import React, { useEffect } from 'react';
import '../../css/Admin.css';
import Container from 'react-bootstrap/Container';
import { DirectGsocNumbers, LocalCountryNumbers } from '../../components/Tables/GsocCallDirectoryTables';
import { useAppDispatch } from '../../context/store';
import { getGsocPhoneNumbersAsync } from '../../slices/contact/contactsSlice';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

const styles = {
    goBackButton: {
        backgroundColor: '#EBECFF',
        width: '100px',
        height: '40px',
        border: 'none',
        color: '#0036C8',
        fontSize: '9pt',
        fontWeight: '500',
        borderRadius: '4px',
        float: 'left' as const
    }
};

export const Contact = (): JSX.Element => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getGsocPhoneNumbersAsync());
    }, []);

    return (
        <Container className='Admin' fluid>
            <div className='DataAnonBody'>
                <Button
                    title='Go Back'
                    size='sm'
                    type='Button'
                    onClick={() => history.goBack()}
                    style={styles.goBackButton}
                >
                    <FaChevronLeft style={{ paddingRight: '5px' }} />
                    Go Back
                </Button>
                <DirectGsocNumbers isAdmin={false} />
                <LocalCountryNumbers isAdmin={false} />
            </div>
        </Container>
    );
};
