import React, { ReactNode } from 'react';
import { GridColDef, GridSortDirection, GridSortModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ButtonProps } from '@mui/material/Button';
import ExportIcon from '@mui/icons-material/SaveAlt';
import { NavLink } from 'react-bootstrap';
import { convertInvalidToNA, phonetoolLinkFormatter } from '../Tables/TableFormatters';
import {
    IncidentsHistoryPropMap,
    IncidentsHistoryTitlePropMap
} from '../../lib/dictionaries/IncidentsHistoryDictionary';
import { downloadRosterCSVAsync } from '../../slices/incidentsHistory/IncidentsHistorySlice';
import { useAppDispatch } from '../../context/store';
import IncidentHistoryDownloadRosterCSVButton from '../Buttons/IncidentHistoryDownloadRosterCSVButton';
import { NA, INCIDENT_TYPES, INCIDENT_NEW_TYPES, INCIDENT_SUB_TYPES } from '../../lib/constants';
import { INCIDENT_DETAILS_LINK_PREFIX } from '../../lib/globals';
import { initialState } from '../../slices/incidentsHistory/IncidentsHistorySlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const downloadRosterLinkFormatter = (params: GridRenderCellParams<any, any, any>): ReactNode => {
    const dispatch = useAppDispatch();
    return (
        <IncidentHistoryDownloadRosterCSVButton
            onClick={() => {
                dispatch(downloadRosterCSVAsync(params.row[IncidentsHistoryPropMap.id]));
            }}
        />
    );
};

export const incidentHistoryColumns: GridColDef[] = [
    {
        field: IncidentsHistoryPropMap.id,
        headerName: IncidentsHistoryTitlePropMap.id,
        flex: 1.4,
        resizable: true,
        renderCell: (params) => {
            const value = convertInvalidToNA(params.value);
            if (value !== NA) {
                return (
                    <NavLink target='_blank' href={`${INCIDENT_DETAILS_LINK_PREFIX}${value}`}>
                        {value}
                    </NavLink>
                );
            }
            return value;
        }
    },
    {
        field: IncidentsHistoryPropMap.country,
        headerName: IncidentsHistoryTitlePropMap.country,
        flex: 0.6,
        resizable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.siteName,
        headerName: IncidentsHistoryTitlePropMap.siteName,
        flex: 0.8,
        resizable: true,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.startTime,
        headerName: IncidentsHistoryTitlePropMap.startTime,
        flex: 1.1,
        type: 'date',
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.endTime,
        headerName: IncidentsHistoryTitlePropMap.endTime,
        flex: 1.1,
        type: 'date',
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.incidentType,
        headerName: IncidentsHistoryTitlePropMap.incidentType,
        type: 'singleSelect',
        valueOptions: Object.values(INCIDENT_TYPES),
        flex: 1.2,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.incidentNewType,
        headerName: IncidentsHistoryTitlePropMap.incidentNewType,
        type: 'singleSelect',
        valueOptions: Object.values(INCIDENT_NEW_TYPES),
        flex: 1.0,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.incidentSubType,
        headerName: IncidentsHistoryTitlePropMap.incidentSubType,
        type: 'singleSelect',
        valueOptions: Object.values(INCIDENT_SUB_TYPES),
        flex: 1.0,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.eventOwner,
        headerName: IncidentsHistoryTitlePropMap.eventOwner,
        flex: 0.6,
        resizable: true,
        renderCell: (params) => phonetoolLinkFormatter(params.value)
    },
    {
        field: IncidentsHistoryPropMap.length,
        headerName: IncidentsHistoryTitlePropMap.length,
        flex: 0.6,
        resizable: true,
        filterable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.onSite,
        headerName: IncidentsHistoryTitlePropMap.onSite,
        flex: 0.6,
        resizable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.numberAccounted,
        headerName: IncidentsHistoryTitlePropMap.numberAccounted,
        flex: 0.65,
        resizable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.percentAccounted,
        headerName: IncidentsHistoryTitlePropMap.percentAccounted,
        flex: 0.65,
        resizable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.totalVisitors,
        headerName: IncidentsHistoryTitlePropMap.totalVisitors,
        flex: 0.6,
        resizable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.countAddedToRoster,
        headerName: IncidentsHistoryTitlePropMap.countAddedToRoster,
        flex: 0.6,
        resizable: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: IncidentsHistoryPropMap.report,
        headerName: IncidentsHistoryTitlePropMap.report,
        flex: 0.6,
        resizable: true,
        filterable: false,
        sortable: false,
        disableExport: true,
        renderCell: downloadRosterLinkFormatter
    }
];

export const DEFAULT_PAGE_SIZE = 50;

export const defaultSortModel: GridSortModel = [
    {
        field: IncidentsHistoryPropMap.startTime,
        sort: 'asc' as GridSortDirection
    }
];

export const dateSortModel: GridSortModel = [
    {
        field: IncidentsHistoryPropMap.startTime,
        sort: 'desc' as GridSortDirection
    }
];

export const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />
};

export const fullCSVFilterAttributes = initialState.filterAttributes;

export const initialSortOrder = initialState.sortOrder;
