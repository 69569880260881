import React from 'react';
import Container from 'react-bootstrap/Container';
import IncidentHistoryDataGrid from '../../components/DataGrids/IncidentsHistoryDataGrid';
import { NavLink } from 'react-bootstrap';
import { FILTER_TIP_ICON_SIZE, RP_BLUE, RP_WIKI_URL, TABLE_FILTER_TIP } from '../../lib/constants';
import '../../css/privacyMessage.css';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const styles = {
    incidentHistoryMessage: {
        fontWeight: '400',
        fontSize: '14px'
    },
    filterTipButton: {
        border: 'none',
        margin: '5px 5px 5px 0',
        background: 'none'
    },
    filterTipText: {
        fontSize: '12px',
        fontWeight: '400',
        marginTop: '9px'
    }
};

const IncidentHistory: React.FunctionComponent = () => {
    const [filterTip, setFilterTip] = React.useState(false);

    return (
        <Container fluid className='ml-2'>
            <div style={{ display: 'flex', flexDirection: 'row' }} className='ml-n3 my-2 container-fluid'>
                <p className='incidentHistoryMessage' style={styles.incidentHistoryMessage}>
                    Note: For data privacy reasons, Personally Identifiable Information (PII) is anonymized
                    two days after an event has closed. All events are retained for only 18 months. See the
                    <NavLink className='incidentHistoryMessageLink' target='_blank' href={RP_WIKI_URL}>
                        Rally Point Wiki
                    </NavLink>
                    for details.
                </p>
                <button onClick={() => setFilterTip(!filterTip)} style={styles.filterTipButton}>
                    <AiOutlineInfoCircle size={FILTER_TIP_ICON_SIZE} color={RP_BLUE} />
                </button>
                {filterTip && <i style={styles.filterTipText}>{TABLE_FILTER_TIP}</i>}
            </div>
            <div className='container-fluid pr-5'>
                <IncidentHistoryDataGrid />
            </div>
        </Container>
    );
};

export default IncidentHistory;
