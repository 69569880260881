export interface ColumnProps {
    columns: string[];
    columnDataMap: { [key: string]: string };
}

const separator = ',';
const lineEnding = '\n';
const specialCharacterSearcher = /("|,|\n)/g;

export interface CSVRow {
    [key: string]: number | boolean | string | null | string[] | undefined;
}

export const createCSV = (rows: CSVRow[], columnProps: ColumnProps): string => {
    const { columns, columnDataMap } = columnProps;
    if (!rows) {
        throw new Error('Parameter rows missing');
    }
    if (!columns || !columns.length) {
        throw new Error('Parameter columnProps.columns missing');
    }
    if (!columnDataMap) {
        throw new Error('Parameter columnProps.columnDataMap missing');
    }
    return (
        columns.join(separator) +
        lineEnding +
        rows
            .map((row) => {
                return columns
                    .map((column) => {
                        const key = columnDataMap[column];
                        let value = row[key];
                        if (!value && typeof value != 'number') {
                            value = '';
                        }
                        if (typeof value == 'number') {
                            value = value.toString(10);
                        }
                        if (typeof value == 'boolean') {
                            value = value ? 'True' : 'False';
                        }
                        if (Array.isArray(value)) {
                            value = value.join(', ');
                        }
                        value = value.replace(/"/g, '""');
                        if (value.search(specialCharacterSearcher) >= 0) {
                            value = `"${value}"`;
                        }
                        return value;
                    })
                    .join(separator);
            })
            .join(lineEnding)
    );
};
