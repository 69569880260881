import React, { Dispatch, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import {
    CONFIRM_ADD_RESOLVER_GROUP_KEY_MESSAGE,
    RESOLVER_GROUP_KEY_EXISTS_MESSAGES,
    RP_BLUE
} from '../../lib/constants';
import { addFacManKeyAsync } from '../../slices/admin/facManKeysSlice';
import { showConfirmationDialog } from '../Commons';

type Props = {
    isVisible: Dispatch<boolean>;
};

const FacManKeyAdd = ({ isVisible }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const { keys } = useSelector((state: RootState) => state.facManKeys);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);

    const handleSubmit = async (): Promise<void> => {
        const confirmed = await showConfirmationDialog({
            title: CONFIRM_ADD_RESOLVER_GROUP_KEY_MESSAGE
        });
        if (confirmed) {
            dispatch(addFacManKeyAsync(name, userCardholderInformation.login));
            isVisible(false);
        }
    };
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header className='modalHeader' closeButton>
                <Modal.Title className='modalTitle'>Add Resolver Group Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    <Form.Group controlId='inputName'>
                        <Form.Label className='formLabel'>Name</Form.Label>
                        <Form.Control
                            className='formControl'
                            as='input'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            isInvalid={keys.some((key) => key.name === name)}
                            placeholder='Enter name...'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {RESOLVER_GROUP_KEY_EXISTS_MESSAGES}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button
                        disabled={!name || keys.some((key) => key.name === name)}
                        className='my-2 saveButton'
                        onClick={handleSubmit}
                    >
                        Add Key
                    </Button>
                    <Button
                        className='mb-2 deleteCancelButton'
                        style={{ color: RP_BLUE }}
                        onClick={() => isVisible(false)}
                    >
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
export default FacManKeyAdd;
