import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { convertInvalidToNA } from '../Tables/TableFormatters';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { RiErrorWarningLine } from 'react-icons/ri';
import semver from 'semver';

const NOT_APPLICABLE_STRING = 'N/A';
const INACTIVE_STRING = 'Inactive';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppVersion = (params: GridRenderCellParams<any, any, any>): JSX.Element => {
    const { latestAppVersion } = useSelector((state: RootState) => state.userProfile);
    const currentAppVersion = convertInvalidToNA(params.value);
    let onLatestVersion = false;
    if (currentAppVersion !== NOT_APPLICABLE_STRING && currentAppVersion !== INACTIVE_STRING) {
        onLatestVersion = semver.gte(currentAppVersion, latestAppVersion);
    }
    return onLatestVersion ? (
        <div className='mt-sm-2'>
            <label>{currentAppVersion}</label>
        </div>
    ) : (
        <div className='mt-sm-2' style={{ color: 'red' }}>
            <label>{currentAppVersion}</label>
            <OverlayTrigger
                placement='right'
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Popover id=''>Not on the latest Rally Point app version ({latestAppVersion})</Popover>
                }
            >
                <RiErrorWarningLine size='20' className='ml-2 mb-sm-1' />
            </OverlayTrigger>
        </div>
    );
};
