import React, { useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsyncAbortable } from 'react-async-hook';
import { useAppDispatch } from '../context/store';
import { searchLoginsAsync } from '../slices/userProfile/userProfileSlice';

type ResultBox<T> = { v: T };

function useConstant<T>(fn: () => T): T {
    const ref = React.useRef<ResultBox<T>>();

    if (!ref.current) {
        ref.current = { v: fn() };
    }

    return ref.current.v;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useSearchLogins = (): { login: string; setLogin: (login: string) => void; searchedLogins: any } => {
    const dispatch = useAppDispatch();
    const [login, setLogin] = useState('');

    const debounceValidatedLogin = useConstant(() =>
        AwesomeDebouncePromise((login: string) => dispatch(searchLoginsAsync(login)), 400)
    );

    const { result } = useAsyncAbortable(
        async (abortSignal, text) => {
            if (text === undefined || text.length < 3) {
                return [];
            } else {
                return debounceValidatedLogin(login);
            }
        },
        [login]
    );

    return {
        login,
        setLogin,
        searchedLogins: result
    };
};

export default useSearchLogins;
