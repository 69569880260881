import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { DateTime } from 'luxon';
import React from 'react';
import { toast } from 'react-toastify';
import { AppThunk } from '../../context/store';
import {
    ADD_LEADER_API_ENDPOINT,
    SEARCH_INCIDENTS_API_ENDPOINT,
    SEARCH_INCIDENT_EVENTS_API_ENDPOINT,
    SEARCH_ROSTER_API_ENDPOINT
} from '../../lib/globals';
import { IncidentsHistoryTitlePropMap } from '../../lib/dictionaries/IncidentsHistoryDictionary';
import { formatToLocalTime, parseEventData } from '../../lib/helpers/helpers';
import { AuditTrail, Incident, Roster } from '../../lib/types';

const { doRequest } = asyncRequest;

interface IncidentRosterSearchRequest {
    incidentId: string;
    pageSize: number;
    pageNumber: number;
    sortOrder?: [
        {
            column: string;
            order: string;
        }
    ];
    filterAttributes?: unknown;
}

interface IncidentAuditTrailSearchRequest {
    incidentId: string;
}

interface AddLeaderRequest {
    incidentId: string;
    leaderLogin: string;
}

interface IncidentDetailsState {
    incident: Incident | null;
    isLoadingIncident: boolean;
    searchIncidentError: string;
    roster: Roster[] | [];
    isSearchingIncidentRoster: boolean;
    searchIncidentRosterError: string;
    currentRosterSearch: {
        pageSize: number;
        pageNumber: number;
        totalCount: number;
        sortOrder?: [
            {
                column: string;
                order: string;
            }
        ];
        filterAttributes?: unknown;
    };
    isAddingLeader: boolean;
    addLeaderError: string;
    leaderLogin: string;
    auditTrail: AuditTrail[] | [];
    isSearchingIncidentAuditTrail: boolean;
    searchIncidentAuditTrailError: string;
}

export const initialState: IncidentDetailsState = {
    incident: null,
    isLoadingIncident: false,
    searchIncidentError: '',
    roster: [],
    isSearchingIncidentRoster: false,
    searchIncidentRosterError: '',
    currentRosterSearch: {
        pageSize: 10,
        pageNumber: 1,
        totalCount: 0
    },
    isAddingLeader: false,
    addLeaderError: '',
    leaderLogin: '',
    auditTrail: [],
    isSearchingIncidentAuditTrail: false,
    searchIncidentAuditTrailError: ''
};

const incidentDetailsSlice = createSlice({
    name: 'incidentDetails',
    initialState,
    reducers: {
        startSearchIncident: (state) => {
            state.searchIncidentError = '';
            state.isLoadingIncident = true;
        },
        searchIncidentSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                incident: Incident[];
            }>
        ) => {
            let { incident } = payload;
            incident = incident.map((incident) => {
                const eventOwner = incident.participants.find((participant) => participant.roleId === 1);
                return {
                    eventOwner: eventOwner ? eventOwner.firstName + ' ' + eventOwner.lastName : '',
                    ...incident
                };
            });
            state.isLoadingIncident = false;
            state.incident = incident[0];
        },
        searchIncidentFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isLoadingIncident = false;
            state.searchIncidentError = error;
        },
        resetIncidentState: (state) => {
            state.incident = initialState.incident;
            state.isLoadingIncident = initialState.isLoadingIncident;
            state.searchIncidentError = initialState.searchIncidentError;
        },
        startSearchIncidentRoster: (state) => {
            state.searchIncidentRosterError = '';
            state.isSearchingIncidentRoster = true;
        },
        searchIncidentRosterSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                roster: Roster[];
                pageSize: number;
                pageNumber: number;
                totalCount: number;
                sortOrder?: [
                    {
                        column: string;
                        order: string;
                    }
                ];
                filterAttributes?: unknown;
            }>
        ) => {
            const { roster, pageSize, pageNumber, totalCount, sortOrder, filterAttributes } = payload;
            state.roster = roster.map((rosterEntry: Roster) => {
                // TODO possibly change how this is represented if person is a visitor
                const accountedFor = Boolean(rosterEntry.accountedFor);
                const lastSeen = formatToLocalTime(DateTime.fromISO(rosterEntry.lastSeen, { zone: 'utc' }));
                const type = rosterEntry.checkInMethod?.name;
                const name = rosterEntry.firstName + ' ' + rosterEntry.lastName;
                return {
                    ...rosterEntry,
                    accountedFor,
                    lastSeen,
                    type,
                    name,
                    accountedBy: rosterEntry.accountedBy?.login ? rosterEntry.accountedBy.login : ''
                };
            });
            state.isSearchingIncidentRoster = false;
            state.currentRosterSearch = {
                pageNumber,
                pageSize,
                totalCount,
                sortOrder,
                filterAttributes
            };
        },
        searchIncidentRosterFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isSearchingIncidentRoster = false;
            state.searchIncidentRosterError = error;
        },
        resetIncidentRosterState: (state) => {
            state.roster = initialState.roster;
            state.currentRosterSearch = initialState.currentRosterSearch;
            state.isSearchingIncidentRoster = initialState.isSearchingIncidentRoster;
            state.searchIncidentRosterError = initialState.searchIncidentRosterError;
        },
        startAddLeader: (state) => {
            state.isAddingLeader = true;
            state.addLeaderError = '';
        },
        addLeaderSuccess: (state) => {
            state.isAddingLeader = initialState.isAddingLeader;
            state.addLeaderError = initialState.addLeaderError;
            state.leaderLogin = initialState.leaderLogin;
        },
        addLeaderFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isAddingLeader = false;
            state.addLeaderError = error;
        },
        setLeaderLogin: (state, { payload }: PayloadAction<{ leaderLogin: string }>) => {
            const { leaderLogin } = payload;
            state.leaderLogin = leaderLogin;
        },
        startSearchIncidentAuditTrail: (state) => {
            state.searchIncidentAuditTrailError = '';
            state.isSearchingIncidentAuditTrail = true;
            state.auditTrail = [];
        },
        searchIncidentAuditTrailSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                auditTrail: AuditTrail[];
            }>
        ) => {
            const { auditTrail } = payload;
            state.auditTrail = auditTrail;
            state.isSearchingIncidentAuditTrail = false;
        },
        searchIncidentAuditTrailFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isSearchingIncidentAuditTrail = false;
            state.searchIncidentAuditTrailError = error;
        }
    }
});

export const {
    startSearchIncident,
    searchIncidentSucceeded,
    searchIncidentFailed,
    resetIncidentState,
    startSearchIncidentRoster,
    searchIncidentRosterSucceeded,
    searchIncidentRosterFailed,
    resetIncidentRosterState,
    startAddLeader,
    addLeaderSuccess,
    addLeaderFailed,
    setLeaderLogin,
    startSearchIncidentAuditTrail,
    searchIncidentAuditTrailSucceeded,
    searchIncidentAuditTrailFailed
} = incidentDetailsSlice.actions;

export default incidentDetailsSlice.reducer;

export const searchIncidentAsync = (incidentId: string | string[]): AppThunk => async (dispatch) => {
    try {
        dispatch(resetIncidentState());
        dispatch(startSearchIncident());
        const request = {
            incidentIds: [incidentId]
        };
        const response = await doRequest(
            SEARCH_INCIDENTS_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        const result = JSON.parse(response.data.body);
        const { incidentData } = result;
        dispatch(
            searchIncidentSucceeded({
                incident: incidentData
            })
        );
    } catch (error) {
        dispatch(searchIncidentFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Search event details failed'} errorMessage={error.message} />
        );
    }
};

export const searchIncidentRosterAsync = (searchRequest: IncidentRosterSearchRequest): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(resetIncidentRosterState());
        dispatch(startSearchIncidentRoster());
        const response = await doRequest(
            SEARCH_ROSTER_API_ENDPOINT,
            searchRequest,
            GuardianAuth.createRequestAuthHeader()
        );
        const result = JSON.parse(response.data.body);
        const { roster, totalCount, pageSize, pageNumber } = result;
        const { sortOrder, filterAttributes } = searchRequest;
        dispatch(
            searchIncidentRosterSucceeded({
                roster,
                totalCount,
                pageSize,
                pageNumber,
                sortOrder,
                filterAttributes
            })
        );
    } catch (error) {
        dispatch(searchIncidentRosterFailed({ error: error.message }));
        toast.error(<ToastErrorMessage header={'Search event roster failed'} errorMessage={error.message} />);
    }
};

export const addLeaderAsync = (incidentId: string, leaderLogin: string): AppThunk => async (dispatch) => {
    const toastId = toast.info('Starting to add leader to event');
    try {
        dispatch(startAddLeader());
        const addLeaderRequest: AddLeaderRequest = {
            incidentId,
            leaderLogin
        };
        const response = await doRequest(
            ADD_LEADER_API_ENDPOINT,
            addLeaderRequest,
            GuardianAuth.createRequestAuthHeader()
        );
        const result = JSON.parse(response.data.body);
        const { response: success } = result;
        if (success) {
            dispatch(addLeaderSuccess());
            toast.update(toastId, { render: 'Leader added', type: toast.TYPE.SUCCESS });
        } else {
            dispatch(addLeaderFailed({ error: result }));
        }
    } catch (error) {
        let message = error.message;
        const { response } = error;
        if (response) {
            const parsedBody = JSON.parse(response.data.body);
            const { error: returnedErrorMessage } = parsedBody;
            message = returnedErrorMessage;
        }
        dispatch(addLeaderFailed({ error: message }));
        toast.update(toastId, {
            render: <ToastErrorMessage header={'Failed to add leader'} errorMessage={message} />,
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const searchIncidentAuditTrailAsync = (
    searchRequest: IncidentAuditTrailSearchRequest
): AppThunk => async (dispatch) => {
    try {
        dispatch(startSearchIncidentAuditTrail());

        const response = await doRequest(
            SEARCH_INCIDENT_EVENTS_API_ENDPOINT,
            { ...searchRequest, sendToWebsocket: false },
            GuardianAuth.createRequestAuthHeader()
        );
        const result = JSON.parse(response.data.body);
        const auditTrail = parseEventData(result.eventsData);

        // Audit trail patch to replace "Incident" strings with "Event" strings
        const updatedAudit: AuditTrail[] = auditTrail.map((audit) => {
            if (audit.role?.length) {
                audit.role = audit.role?.replace(/incident commander/gi, 'Event Owner');
            }
            if (audit.message?.length) {
                audit.message = audit.message
                    ?.replace(/incident id/gi, 'Event ID')
                    .replace(/Incident Commander/gi, 'Event Owner');
            }
            return audit;
        });
        dispatch(
            searchIncidentAuditTrailSucceeded({
                auditTrail: updatedAudit
            })
        );
    } catch (error) {
        dispatch(searchIncidentAuditTrailFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Search event audit trail failed'} errorMessage={error.message} />
        );
    }
};
