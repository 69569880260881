import React, { Dispatch, useState, useEffect } from 'react';
import 'animate.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { MdErrorOutline } from 'react-icons/md';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { DateTime } from 'luxon';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import {
    addAnonymizationSettingAsync,
    deleteAnonymizationSettingAsync,
    updateAnonymizationSettingAsync
} from '../../slices/admin/dataAnonymizationSlice';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import {
    INVALID_DAYS_MESSAGE,
    CONFIRM_ADD_OVERRIDE_MESSAGE,
    CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE,
    CONFIRM_EDIT_OVERRIDE_MESSAGE,
    CONFIRM_DELETE_OVERRIDE_MESSAGE,
    OVERRIDE_EXISTS_MESSAGE,
    RP_BLUE
} from '../../lib/constants';
import { toast } from 'react-toastify';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import { showConfirmationDialog } from '../Commons';

export enum OverrideTypes {
    AddOverride = 'Add Override',
    EditOverride = 'Edit Override',
    EditDefault = 'Change Default Override'
}

type Props = {
    overrideType: OverrideTypes;
    isVisible: Dispatch<boolean>;
    selectedCountry?: string;
    selectedDays?: number | null;
};

type countryOption = {
    value: string;
    label: string;
};

const Override = ({ overrideType, isVisible, selectedCountry, selectedDays }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [country, setCountry] = useState<countryOption>();
    const [days, setDays] = useState(selectedDays);
    const [neverAnonymized, setNeverAnonymized] = useToggle(selectedDays === null);
    const [isCountryValid, setIsCountryValid] = useToggle(false);
    const { anonymizationSettings } = useSelector((state: RootState) => state.dataAnonymization);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);
    countries.registerLocale(localeEn);

    useEffect(() => {
        setIsCountryValid(!anonymizationSettings.some((setting) => setting.countryCode === country?.value));
    }, [country, anonymizationSettings]);

    const handleSubmit = async (): Promise<void> => {
        let inputDays;
        if (neverAnonymized) {
            inputDays = null;
        } else if (days == null || isNaN(days)) {
            inputDays = 0;
        } else {
            inputDays = days;
        }
        if (overrideType === OverrideTypes.AddOverride) {
            if (!country || !country.value) {
                return;
            }
            const confirmed = await showConfirmationDialog({
                title: CONFIRM_ADD_OVERRIDE_MESSAGE
            });
            if (confirmed) {
                dispatch(
                    addAnonymizationSettingAsync({
                        countryCode: country.value,
                        days: inputDays,
                        dateLastModified: DateTime.utc(),
                        modifiedBy: userCardholderInformation.login
                    })
                );
                isVisible(false);
            }
        } else if (overrideType === OverrideTypes.EditOverride) {
            const confirmed = await showConfirmationDialog({
                title: CONFIRM_EDIT_OVERRIDE_MESSAGE
            });
            if (confirmed && selectedCountry != null) {
                const alpha2Code = countries.getAlpha2Code(selectedCountry, 'en');
                if (alpha2Code) {
                    dispatch(
                        updateAnonymizationSettingAsync({
                            countryCode: alpha2Code,
                            days: inputDays,
                            dateLastModified: DateTime.utc(),
                            modifiedBy: userCardholderInformation.login
                        })
                    );
                } else {
                    toast.error(
                        <ToastErrorMessage
                            header={'Invalid country!'}
                            errorMessage={'Could not retrieve valid alpha-2 country code'}
                        />
                    );
                }
                isVisible(false);
            }
        } else {
            const confirmed = await showConfirmationDialog({
                title: CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE
            });
            if (confirmed) {
                dispatch(
                    updateAnonymizationSettingAsync({
                        countryCode: 'DEFAULT',
                        days: inputDays,
                        dateLastModified: DateTime.utc(),
                        modifiedBy: userCardholderInformation.login
                    })
                );
                isVisible(false);
            }
        }
    };

    const handleDelete = async (): Promise<void> => {
        const confirmed = await showConfirmationDialog({
            title: CONFIRM_DELETE_OVERRIDE_MESSAGE,
            icon: 'warning',
            iconColor: '#F29718'
        });
        if (confirmed && selectedCountry != null) {
            const alpha2Code = countries.getAlpha2Code(selectedCountry, 'en');
            if (alpha2Code) {
                dispatch(deleteAnonymizationSettingAsync(alpha2Code));
            } else {
                toast.error(
                    <ToastErrorMessage
                        header={'Invalid country!'}
                        errorMessage={'Could not retrieve valid alpha-2 country code'}
                    />
                );
            }
            isVisible(false);
        }
    };

    const countryOptions = Object.entries(countries.getNames('en')).map(([countryCode, countryName]) => ({
        value: countryCode,
        label: countryName
    }));
    const customSelectStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: !isCountryValid ? 'red' : '#667085',
            '&:hover': {
                borderColor: !isCountryValid ? 'red' : '#667085'
            },
            boxShadow: !isCountryValid ? '0 0 0 1px red' : state.isFocused ? `0 0 0 1px ${RP_BLUE}` : 'none',
            fontSize: '14px'
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            fontSize: '14px'
        })
    };

    const ErrorIndicator = (): JSX.Element | null => {
        return !isCountryValid ? <MdErrorOutline size={16} style={{ color: '#E52817' }} /> : null;
    };

    return (
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header className='modalHeader' closeButton>
                <Modal.Title className='modalTitle'>{overrideType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    {overrideType === OverrideTypes.AddOverride && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label className='formLabel'>Country</Form.Label>
                            <Select
                                styles={customSelectStyles}
                                options={countryOptions}
                                onChange={(option: countryOption) => setCountry(option)}
                                components={{ IndicatorSeparator: ErrorIndicator }}
                                placeholder={
                                    <Form.Text className='Placeholder'>Select a country...</Form.Text>
                                }
                                value={country}
                            />
                            {!isCountryValid && (
                                <Form.Text style={{ fontSize: '14px', color: '#E52817' }}>
                                    {OVERRIDE_EXISTS_MESSAGE}
                                </Form.Text>
                            )}
                        </Form.Group>
                    )}
                    {overrideType === OverrideTypes.EditOverride && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label className='formLabel'>Country</Form.Label>
                            <Select
                                styles={customSelectStyles}
                                value={{
                                    value: countries.getAlpha2Code(selectedCountry as string, 'en'),
                                    label: selectedCountry
                                }}
                                isDisabled={true}
                            />
                        </Form.Group>
                    )}
                    <Form.Group className='mb-3' controlId='inputDays'>
                        <Form.Label className='formLabel'>Days</Form.Label>
                        <Form.Control
                            className='formControl'
                            type='number'
                            placeholder='0-365'
                            onChange={(event) => setDays(parseInt(event.target.value))}
                            isInvalid={days != null && (days < 0 || days > 365)}
                            value={days == null ? '' : days}
                            disabled={neverAnonymized}
                        />
                        <Form.Control.Feedback style={{ fontSize: '14px', color: '#E52817' }} type='invalid'>
                            {INVALID_DAYS_MESSAGE}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group style={{ zIndex: '0' }} className='mb-3' controlId='toggleAnonymize'>
                        <Form inline>
                            <Form.Check
                                type='switch'
                                onChange={setNeverAnonymized}
                                checked={neverAnonymized}
                            />
                            <Form.Label className='formLabel'>Never Anonymize?</Form.Label>
                        </Form>
                    </Form.Group>
                    <Button
                        className='mb-2 saveButton'
                        disabled={
                            ((!country || !country.value) && !selectedCountry) ||
                            (days != null && (days < 0 || days > 365)) ||
                            (overrideType === OverrideTypes.AddOverride && !isCountryValid)
                        }
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    {overrideType === OverrideTypes.EditOverride && (
                        <Button
                            className='mb-4 deleteCancelButton'
                            style={{ color: '#E52817' }}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        className='mb-2 deleteCancelButton'
                        style={{ color: RP_BLUE }}
                        onClick={() => isVisible(false)}
                    >
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default Override;
