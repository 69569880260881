import 'bootstrap/dist/css/bootstrap.min.css';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import createGuardianAuthStore from 'GuardianAuth/lib/store/guardianAuthStore';
import GuardianPermissionsProvider from 'GuardianPermissions/lib/components/GuardianPermissionsProvider';
import { SERVER_ENDPOINTS } from 'GuardianPermissions/lib/config/globals';
import { AvailableCasbinModels } from 'GuardianPolicyManager/lib/config/casbinModels';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactDOM from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from 'redux';
import App from './App';
import './css/index.css';
import {
    API_GATEWAY_API_KEY,
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_REDIRECT_TO,
    COGNITO_USERPOOL,
    MUI_LICENSE_KEY
} from './lib/globals';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY ? MUI_LICENSE_KEY : '');

SERVER_ENDPOINTS.permissionsEndpoint = '/rallypoint/getPolicies';
SERVER_ENDPOINTS.permissionsByDomainEndpoint = '/rallypoint/getPoliciesByDomains';
// SERVER_ENDPOINTS.validateTeams = '/rallypoint/validateTeams';

GuardianAuth.init(
    createGuardianAuthStore(),
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_USERPOOL,
    API_GATEWAY_API_KEY,
    COGNITO_REDIRECT_TO
);

GuardianAuth.setTitle('Tester');

export default function showPage(guardianAuthStore: Store): void {
    ReactDOM.render(
        <Provider store={guardianAuthStore} context={AUTH_CONTEXT}>
            <GuardianPermissionsProvider
                profileName={'RP2'}
                // Facman model is the standard model (RBAC)
                modelName={AvailableCasbinModels.FacManCasbinModel}
            >
                <App />
            </GuardianPermissionsProvider>
        </Provider>,
        document.getElementById('root')
    );
}

if (GuardianAuth.guardianAuthStore) {
    GuardianAuth.authAndShowPage(() => {
        showPage(GuardianAuth.guardianAuthStore);
    });
}
