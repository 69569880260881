import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../context/store';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import { toast } from 'react-toastify';
import {
    ComposedWithAuthAndPermissionsProps,
    defaultUserPermissionActions,
    defaultUserPermissionRoles,
    PolicyItem,
    UserPermissionActions,
    UserPermissionRoles
} from '../../lib/types';
import { useEnforcePermissions } from '../../lib/helpers/helpers';
import { DOMAINS, RALLY_POINT_PERMISSIONS } from '../../lib/constants';

interface UserPermissionsState {
    userPermissionRoles: UserPermissionRoles;
    userPermissionActions: UserPermissionActions;
    isGettingUserPermissionRoles: boolean;
    isGettingUserPermissionActions: boolean;
    getUserPermissionRolesError: string;
    getUserPermissionActionsError: string;
}

export const initialState: UserPermissionsState = {
    userPermissionRoles: defaultUserPermissionRoles(),
    userPermissionActions: defaultUserPermissionActions(),
    isGettingUserPermissionRoles: false,
    isGettingUserPermissionActions: false,
    getUserPermissionRolesError: '',
    getUserPermissionActionsError: ''
};

const userPermissionsSlice = createSlice({
    name: 'userPermissions',
    initialState,
    reducers: {
        startGettingUserPermissionRoles: (state) => {
            state.userPermissionRoles = initialState.userPermissionRoles;
            state.getUserPermissionRolesError = initialState.getUserPermissionRolesError;
            state.isGettingUserPermissionRoles = true;
        },
        getUserPermissionRolesSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                webRole: UserPermissionRoles['webRole'];
                mobileRole: UserPermissionRoles['mobileRole'];
            }>
        ) => {
            const userPermissionRoles = payload;
            state.isGettingUserPermissionRoles = false;
            state.userPermissionRoles = userPermissionRoles;
        },
        getUserPermissionRolesFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingUserPermissionRoles = false;
            state.getUserPermissionRolesError = error;
        },
        startGettingUserPermissionActions: (state) => {
            state.userPermissionActions = initialState.userPermissionActions;
            state.getUserPermissionActionsError = initialState.getUserPermissionActionsError;
            state.isGettingUserPermissionActions = true;
        },
        getUserPermissionActionsSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                showOSR: boolean;
                showIncidentDetails: boolean;
                showIncidentHistory: boolean;
                showAdmin: boolean;
                testAccess: boolean;
            }>
        ) => {
            const userPermissionActions = payload;
            state.isGettingUserPermissionActions = false;
            state.userPermissionActions = userPermissionActions;
        },
        getUserPermissionActionsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingUserPermissionActions = false;
            state.getUserPermissionActionsError = error;
        }
    }
});

export const {
    startGettingUserPermissionRoles,
    getUserPermissionRolesSucceeded,
    getUserPermissionRolesFailed,
    startGettingUserPermissionActions,
    getUserPermissionActionsSucceeded,
    getUserPermissionActionsFailed
} = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;

export const getUserPermissionRolesAsync = ({
    permissionsContext,
    userContext
}: ComposedWithAuthAndPermissionsProps): AppThunk => async (dispatch) => {
    try {
        let webRole: UserPermissionRoles['webRole'] = 'User';
        let mobileRole: UserPermissionRoles['mobileRole'] = 'User';
        dispatch(startGettingUserPermissionRoles());
        const enforcementsArray = [
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.EVENT_OWNER.OBJECT,
                RALLY_POINT_PERMISSIONS.EVENT_OWNER.ACTION,
                DOMAINS.DEFAULT
            ),
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.BUSINESS.OBJECT,
                RALLY_POINT_PERMISSIONS.BUSINESS.ACTION,
                DOMAINS.DEFAULT
            ),
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.ADMIN.OBJECT,
                RALLY_POINT_PERMISSIONS.ADMIN.ACTION,
                DOMAINS.DEFAULT
            )
        ];
        const [isEventOwner, isBusiness, isAdmin] = await Promise.all(enforcementsArray);
        if (isAdmin) {
            webRole = 'Admin';
            mobileRole = 'Admin';
        } else {
            if (isEventOwner) {
                webRole = 'Event Owner';
                mobileRole = 'Event Owner';
            }
            if (isBusiness) {
                webRole = 'Business Team';
            }
        }
        dispatch(
            getUserPermissionRolesSucceeded({
                webRole,
                mobileRole
            })
        );
    } catch (error) {
        dispatch(getUserPermissionRolesFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to get user's permission roles`}
                errorMessage={error.message}
            />
        );
    }
};

export const getUserPermissionActionsAsync = ({
    permissionsContext,
    userContext
}: ComposedWithAuthAndPermissionsProps): AppThunk => async (dispatch) => {
    try {
        dispatch(startGettingUserPermissionActions());
        const enforcementsArray = [
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.SHOW_OSR.OBJECT,
                RALLY_POINT_PERMISSIONS.SHOW_OSR.ACTION,
                DOMAINS.DEFAULT
            ),
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.SHOW_INCIDENT_DETAILS.OBJECT,
                RALLY_POINT_PERMISSIONS.SHOW_INCIDENT_DETAILS.ACTION,
                DOMAINS.DEFAULT
            ),
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.SHOW_INCIDENT_HISTORY.OBJECT,
                RALLY_POINT_PERMISSIONS.SHOW_INCIDENT_HISTORY.ACTION,
                DOMAINS.DEFAULT
            ),
            useEnforcePermissions(
                permissionsContext.initialized ? permissionsContext.initialized : false,
                userContext.empId,
                RALLY_POINT_PERMISSIONS.SHOW_ADMIN.OBJECT,
                RALLY_POINT_PERMISSIONS.SHOW_ADMIN.ACTION,
                DOMAINS.DEFAULT
            )
        ];
        const [showOSR, showIncidentDetails, showIncidentHistory, showAdmin] = await Promise.all(
            enforcementsArray
        );
        const { policyItems } = permissionsContext;
        const testAccess = policyItems[DOMAINS.DEFAULT]
            ? policyItems[DOMAINS.DEFAULT].some(
                  (policyItem: PolicyItem) =>
                      policyItem.object === RALLY_POINT_PERMISSIONS.TEST_ACCESS.OBJECT &&
                      policyItem.action === RALLY_POINT_PERMISSIONS.TEST_ACCESS.ACTION
              )
            : false;
        dispatch(
            getUserPermissionActionsSucceeded({
                showOSR,
                showIncidentDetails,
                showIncidentHistory,
                showAdmin,
                testAccess
            })
        );
    } catch (error) {
        dispatch(getUserPermissionActionsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to get user's permission actions`}
                errorMessage={error.message}
            />
        );
    }
};
