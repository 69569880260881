import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTimeoutFn } from 'react-use';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import IncidentCard from '../../components/Cards/IncidentCard';
import { useSelector } from 'react-redux';
import { searchIncidentsAsync } from '../../slices/incidents/incidentSlice';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import { Incident } from '../../lib/types';
import ToggleAutoReloadSwitch from '../../components/Buttons/ToggleAutoReloadSwitch';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import { TABLE_REFRESH_TIME } from '../../lib/constants';

const styles = {
    autoReloadToggleSwitch: {
        marginTop: '5px',
        marginBottom: '5px'
    },
    cardHeaderStyles: {
        backgroundColor: '#0D4AFF',
        color: 'white',
        textAlign: 'center' as const,
        fontSize: '1.2rem',
        fontWeight: '500'
    },
    cardBodyStyles: {
        textAlign: 'center' as const,
        fontWeight: 'bold' as const,
        fontSize: '1rem',
        paddingLeft: '30px',
        paddingRight: '30px'
    }
};

const Incidents: React.FunctionComponent = () => {
    const [onGoingIncidents, setOnGoingIncidents] = useState<Array<Incident>>([]);
    const [completedIncidents, setCompletedIncidents] = useState<Array<Incident>>([]);
    const [currentTab, setCurrentTab] = useState<string>('all');
    const [isCorpForOnGoing, setIsCorpForOnGoing] = useState<boolean>(false);
    const [isCorpForCompleted, setIsCorpForCompleted] = useState<boolean>(false);
    const [showTabs, setShowTabs] = useState<boolean>(false);
    const { incidents, isLoadingIncidents } = useSelector((state: RootState) => state.incidents);
    const { webRole } = useSelector((state: RootState) => state.userPermissions?.userPermissionRoles);
    const dispatch = useAppDispatch();
    const reloadIncidents = (): void => {
        dispatch(searchIncidentsAsync());
    };
    const [isReady, cancel, reset] = useTimeoutFn(reloadIncidents, TABLE_REFRESH_TIME);

    useEffect(() => {
        setShowTabs(webRole === 'Admin' || webRole === 'Business Team');
    }, [webRole]);

    useEffect(() => {
        dispatch(searchIncidentsAsync());
    }, [dispatch]);

    useEffect(() => {
        setOnGoingIncidents(incidents.filter((incident) => incident.isOpen));
        setCompletedIncidents(incidents.filter((incident) => !incident.isOpen));
    }, [incidents]);

    useEffect(() => {
        setIsCorpForOnGoing(onGoingIncidents.some((incident) => incident.isCorporate));
    }, [onGoingIncidents]);

    useEffect(() => {
        setIsCorpForCompleted(completedIncidents.some((incident) => incident.isCorporate));
    }, [completedIncidents]);

    if (isReady()) {
        reset();
    }

    return (
        <Container>
            <DimmedLoader active={isLoadingIncidents}>
                <ToggleAutoReloadSwitch
                    cancel={() => {
                        cancel();
                    }}
                    reset={() => {
                        reset();
                    }}
                    style={styles.autoReloadToggleSwitch}
                />

                {showTabs && (isCorpForOnGoing || isCorpForCompleted) ? (
                    <Tabs
                        variant='pills'
                        id='ongoingIncidentTabs'
                        defaultActiveKey='all'
                        activeKey={currentTab}
                        onSelect={(key) => setCurrentTab(key ? key : 'all')}
                        className='custom-tabs ml-3'
                    >
                        <Tab eventKey='all' title={'All'}>
                            <Card className={'my-3'}>
                                <Card.Header style={styles.cardHeaderStyles}>On Going Events</Card.Header>
                                {onGoingIncidents.length ? (
                                    <Card.Body style={styles.cardBodyStyles}>
                                        <Row>
                                            {onGoingIncidents.map((onGoingIncident: Incident) => (
                                                <Col key={onGoingIncident.id} md={4} className='px-2 py-2'>
                                                    <IncidentCard
                                                        key={onGoingIncident.id}
                                                        incident={onGoingIncident}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>

                            <Card>
                                <Card.Header style={styles.cardHeaderStyles}>Completed Events</Card.Header>
                                {completedIncidents.length ? (
                                    <Card.Body style={styles.cardBodyStyles}>
                                        <Row>
                                            {completedIncidents.map((completedIncident: Incident) => (
                                                <Col key={completedIncident.id} md={4} className='px-2 py-2'>
                                                    <IncidentCard
                                                        key={completedIncident.id}
                                                        incident={completedIncident}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>
                        </Tab>
                        <Tab eventKey='ops' title={'Operations'}>
                            <Card className={'my-3'}>
                                <Card.Header style={styles.cardHeaderStyles}>
                                    On Going Operations Events
                                </Card.Header>
                                {onGoingIncidents.length ? (
                                    <Card.Body style={styles.cardBodyStyles}>
                                        <Row>
                                            {onGoingIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) =>
                                                        !onGoingIncident.isCorporate
                                                )
                                                .map((onGoingIncident: Incident) => (
                                                    <Col
                                                        key={onGoingIncident.id}
                                                        md={4}
                                                        className='px-2 py-2'
                                                    >
                                                        <IncidentCard
                                                            key={onGoingIncident.id}
                                                            incident={onGoingIncident}
                                                        />
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>

                            <Card>
                                <Card.Header style={styles.cardHeaderStyles}>
                                    Completed Operations Events
                                </Card.Header>
                                {completedIncidents.length ? (
                                    <Card.Body style={styles.cardBodyStyles}>
                                        <Row>
                                            {completedIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) =>
                                                        !onGoingIncident.isCorporate
                                                )
                                                .map((completedIncident: Incident) => (
                                                    <Col
                                                        key={completedIncident.id}
                                                        md={4}
                                                        className='px-2 py-2'
                                                    >
                                                        <IncidentCard
                                                            key={completedIncident.id}
                                                            incident={completedIncident}
                                                        />
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>
                        </Tab>
                        <Tab eventKey='corp' title={'Corporate'}>
                            <Card className={'my-3'}>
                                <Card.Header style={styles.cardHeaderStyles}>
                                    On Going Corporate Events
                                </Card.Header>
                                {onGoingIncidents.length ? (
                                    <Card.Body style={styles.cardBodyStyles}>
                                        <Row>
                                            {onGoingIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) => onGoingIncident.isCorporate
                                                )
                                                .map((onGoingIncident: Incident) => (
                                                    <Col
                                                        key={onGoingIncident.id}
                                                        md={4}
                                                        className='px-2 py-2'
                                                    >
                                                        <IncidentCard
                                                            key={onGoingIncident.id}
                                                            incident={onGoingIncident}
                                                        />
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>

                            <Card>
                                <Card.Header style={styles.cardHeaderStyles}>
                                    Completed Corporate Events
                                </Card.Header>
                                {completedIncidents.length ? (
                                    <Card.Body style={styles.cardBodyStyles}>
                                        <Row>
                                            {completedIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) => onGoingIncident.isCorporate
                                                )
                                                .map((completedIncident: Incident) => (
                                                    <Col
                                                        key={completedIncident.id}
                                                        md={4}
                                                        className='px-2 py-2'
                                                    >
                                                        <IncidentCard
                                                            key={completedIncident.id}
                                                            incident={completedIncident}
                                                        />
                                                    </Col>
                                                ))}
                                        </Row>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>
                        </Tab>
                    </Tabs>
                ) : (
                    <>
                        <Card className={'my-3'}>
                            <Card.Header style={styles.cardHeaderStyles}>On Going Events</Card.Header>
                            {onGoingIncidents.length ? (
                                <Card.Body style={styles.cardBodyStyles}>
                                    <Row>
                                        {onGoingIncidents.map((onGoingIncident: Incident) => (
                                            <Col key={onGoingIncident.id} md={4} className='px-2 py-2'>
                                                <IncidentCard
                                                    key={onGoingIncident.id}
                                                    incident={onGoingIncident}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Card.Body>
                            ) : (
                                <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                            )}
                        </Card>

                        <Card>
                            <Card.Header style={styles.cardHeaderStyles}>Completed Events</Card.Header>
                            {completedIncidents.length ? (
                                <Card.Body style={styles.cardBodyStyles}>
                                    <Row>
                                        {completedIncidents.map((completedIncident: Incident) => (
                                            <Col key={completedIncident.id} md={4} className='px-2 py-2'>
                                                <IncidentCard
                                                    key={completedIncident.id}
                                                    incident={completedIncident}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Card.Body>
                            ) : (
                                <Card.Body style={styles.cardBodyStyles}>None</Card.Body>
                            )}
                        </Card>
                    </>
                )}
            </DimmedLoader>
        </Container>
    );
};

export default Incidents;
