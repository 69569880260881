import React from 'react';

const IncidentHistoryDownloadRosterCSVButton: ({ onClick }: { onClick: () => void }) => JSX.Element = ({
    onClick
}) => {
    return (
        <a href={'#'} onClick={onClick}>
            CSV
        </a>
    );
};

export default IncidentHistoryDownloadRosterCSVButton;
