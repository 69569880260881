import React, { useEffect } from 'react';
import '../../css/Admin.css';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import Container from 'react-bootstrap/Container';
import { DirectGsocNumbers, LocalCountryNumbers } from '../../components/Tables/GsocCallDirectoryTables';
import { useAppDispatch } from '../../context/store';
import { getGsocPhoneNumbersAsync } from '../../slices/contact/contactsSlice';

export const GsocCallDirectory = (): JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getGsocPhoneNumbersAsync());
    }, []);

    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <DirectGsocNumbers isAdmin={true} />
                <LocalCountryNumbers isAdmin={true} />
            </div>
        </Container>
    );
};
