import React, { Dispatch, useState, useEffect } from 'react';
import 'animate.css';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import '../../css/Modal.css';
import { MdErrorOutline } from 'react-icons/md';
import { FaTrashCan } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import 'react-phone-input-2/lib/bootstrap.css';
import { RootState } from '../../context/rootReducer';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useAppDispatch } from '../../context/store';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import {
    CONFIRM_ADD_LOCAL_PHONE_NUMBER_MESSAGE,
    CONFIRM_CHANGE_GSOC_PHONE_NUMBER_MESSAGE,
    CONFIRM_CHANGE_LOCAL_PHONE_NUMBER_MESSAGE,
    DIRECT_GSOC_NUMBERS_COUNTRY,
    LOCAL_COUNTRY_NUMBER_EXISTS_MESSAGE,
    RP_BLUE,
    UNITED_STATES_ALPHA_2_CODE
} from '../../lib/constants';
import { useToggle } from 'react-use';
import { showConfirmationDialog } from '../Commons';
import { addGsocPhoneNumberAsync, updateGsocPhoneNumberAsync } from '../../slices/contact/contactsSlice';

countries.registerLocale(localeEn);

export enum PhoneNumberTypes {
    AddLocalNumber = 'Add Local Number',
    ChangeGsocNumber = 'Change GSOC Number',
    ChangeLocalNumber = 'Change Local Number'
}

type countryOption = {
    value: string;
    label: string;
};

type Props = {
    phoneNumberType: PhoneNumberTypes;
    isVisible: Dispatch<boolean>;
    selectedCountry?: string;
    selectedPhoneNumbers?: string[];
    selectedDialCode?: string;
};

const GsocPhoneNumber = ({
    phoneNumberType,
    isVisible,
    selectedCountry,
    selectedPhoneNumbers,
    selectedDialCode
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [country, setCountry] = useState<countryOption | null>(
        selectedCountry
            ? {
                  value: countries.getAlpha2Code(selectedCountry, 'en') ?? '',
                  label: selectedCountry
              }
            : null
    );
    const [isCountryValid, setIsCountryValid] = useToggle(false);
    const [firstPhoneNumber, setFirstPhoneNumber] = useState(selectedPhoneNumbers?.[0] ?? '');
    const [secondPhoneNumber, setSecondPhoneNumber] = useState(selectedPhoneNumbers?.[1] ?? '');
    const [dialCode, setDialCode] = useState(selectedDialCode ?? '');
    const { gsocPhoneNumbers } = useSelector((state: RootState) => state.contacts);

    useEffect(() => {
        setIsCountryValid(!gsocPhoneNumbers.some((setting) => setting.country === country?.label));
    }, [country, gsocPhoneNumbers]);

    const countryAliases: Record<string, string> = {
        "People's Republic of China": 'China',
        'Taiwan, Province of China': 'Taiwan',
        'United States of America': 'United States'
    };

    const countryOptions = Object.entries(countries.getNames('en')).map(([countryCode, countryName]) => ({
        value: countryCode,
        label: countryAliases[countryName] || countryName
    }));

    const customSelectStyles = (isCountrySelect: boolean): any => ({
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: isCountrySelect && !isCountryValid ? 'red' : '#667085',
            '&:hover': {
                borderColor: isCountrySelect && !isCountryValid ? 'red' : '#667085'
            },
            boxShadow:
                isCountrySelect && !isCountryValid
                    ? '0 0 0 1px red'
                    : state.isFocused
                    ? `0 0 0 1px ${RP_BLUE}`
                    : 'none',
            fontSize: '14px'
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            fontSize: '14px'
        })
    });

    const ErrorIndicator = (): JSX.Element | null => {
        return !isCountryValid ? <MdErrorOutline size={16} style={{ color: '#E52817' }} /> : null;
    };

    const handleSubmit = async (): Promise<void> => {
        const phoneNumberInput = [firstPhoneNumber, secondPhoneNumber].filter(
            (number): number is string => number != null && number.length > 0
        );
        if (country && phoneNumberInput.length) {
            if (phoneNumberType === PhoneNumberTypes.AddLocalNumber) {
                const confirmed = await showConfirmationDialog({
                    title: CONFIRM_ADD_LOCAL_PHONE_NUMBER_MESSAGE
                });
                if (confirmed) {
                    dispatch(
                        addGsocPhoneNumberAsync({
                            country: country.label,
                            dialCode: dialCode,
                            phoneNumbers: phoneNumberInput
                        })
                    );
                    isVisible(false);
                }
            } else if (phoneNumberType === PhoneNumberTypes.ChangeLocalNumber) {
                const confirmed = await showConfirmationDialog({
                    title: CONFIRM_CHANGE_LOCAL_PHONE_NUMBER_MESSAGE
                });
                if (confirmed && selectedCountry != null) {
                    dispatch(
                        updateGsocPhoneNumberAsync({
                            country: selectedCountry,
                            dialCode: dialCode,
                            phoneNumbers: phoneNumberInput
                        })
                    );
                    isVisible(false);
                }
            } else if (phoneNumberType === PhoneNumberTypes.ChangeGsocNumber) {
                const confirmed = await showConfirmationDialog({
                    title: CONFIRM_CHANGE_GSOC_PHONE_NUMBER_MESSAGE
                });
                if (confirmed) {
                    dispatch(
                        updateGsocPhoneNumberAsync({
                            country: DIRECT_GSOC_NUMBERS_COUNTRY,
                            dialCode: dialCode,
                            phoneNumbers: phoneNumberInput
                        })
                    );
                    isVisible(false);
                }
            }
        }
    };

    return (
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header className='modalHeader' closeButton>
                <Modal.Title className='modalTitle'>{phoneNumberType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    {phoneNumberType !== PhoneNumberTypes.ChangeGsocNumber && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label className='formLabel'>Country</Form.Label>
                            <Select
                                styles={customSelectStyles(
                                    phoneNumberType === PhoneNumberTypes.AddLocalNumber
                                )}
                                options={countryOptions}
                                onChange={(option: countryOption) => {
                                    setCountry(option);
                                    setFirstPhoneNumber('');
                                    setSecondPhoneNumber('');
                                }}
                                components={{
                                    IndicatorSeparator:
                                        phoneNumberType === PhoneNumberTypes.AddLocalNumber
                                            ? ErrorIndicator
                                            : () => null
                                }}
                                placeholder={
                                    <Form.Text className='Placeholder'>Select a country...</Form.Text>
                                }
                                value={country}
                                isDisabled={phoneNumberType === PhoneNumberTypes.ChangeLocalNumber}
                            />
                            {phoneNumberType === PhoneNumberTypes.AddLocalNumber && !isCountryValid && (
                                <Form.Text style={{ fontSize: '14px', color: '#E52817' }}>
                                    {LOCAL_COUNTRY_NUMBER_EXISTS_MESSAGE}
                                </Form.Text>
                            )}
                        </Form.Group>
                    )}
                    <Form.Group controlId='inputCountry'>
                        <Form.Label className='formLabel'>Phone Number 1</Form.Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PhoneInput
                                key={country?.value}
                                onChange={(
                                    value: string,
                                    countryData: CountryData,
                                    e: React.ChangeEvent<HTMLInputElement>,
                                    formattedValue: string
                                ) => {
                                    setFirstPhoneNumber(formattedValue);
                                    setDialCode(`+${countryData.dialCode}`);
                                }}
                                country={country?.value.toLowerCase()}
                                onlyCountries={
                                    country
                                        ? [country.value.toLowerCase() || UNITED_STATES_ALPHA_2_CODE]
                                        : undefined
                                }
                                disableInitialCountryGuess={!firstPhoneNumber}
                                value={firstPhoneNumber}
                                placeholder={'Enter a number'}
                                inputClass='customPhoneInput'
                            />
                            <Button className='trashButton' onClick={() => setFirstPhoneNumber('')}>
                                <FaTrashCan size={20} />
                            </Button>
                        </div>
                    </Form.Group>
                    <Form.Group controlId='inputCountry'>
                        <Form.Label className='formLabel'>Phone Number 2</Form.Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PhoneInput
                                key={country?.value}
                                onChange={(
                                    value: string,
                                    countryData: CountryData,
                                    e: React.ChangeEvent<HTMLInputElement>,
                                    formattedValue: string
                                ) => {
                                    setSecondPhoneNumber(formattedValue);
                                    setDialCode(`+${countryData.dialCode}`);
                                }}
                                country={country?.value.toLowerCase()}
                                onlyCountries={
                                    country
                                        ? [country.value.toLowerCase() || UNITED_STATES_ALPHA_2_CODE]
                                        : undefined
                                }
                                disableInitialCountryGuess={!secondPhoneNumber}
                                value={secondPhoneNumber}
                                placeholder={'Enter a number'}
                                inputClass='customPhoneInput'
                            />
                            <Button className='trashButton' onClick={() => setSecondPhoneNumber('')}>
                                <FaTrashCan size={20} />
                            </Button>
                        </div>
                    </Form.Group>
                    <Button
                        className='my-2 saveButton'
                        disabled={
                            !country ||
                            (!firstPhoneNumber && !secondPhoneNumber) ||
                            (phoneNumberType === PhoneNumberTypes.AddLocalNumber && !isCountryValid) ||
                            [firstPhoneNumber, secondPhoneNumber].some(
                                (number) => number && !number.startsWith(dialCode)
                            )
                        }
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    <Button
                        className='mb-2 deleteCancelButton'
                        style={{ color: RP_BLUE }}
                        onClick={() => isVisible(false)}
                    >
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default GsocPhoneNumber;
