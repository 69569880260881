import React, { useEffect } from 'react';
import '../../../css/Admin.css';
import AdminSidebar from '../../../components/Sidebar/AdminSidebar';
import Container from 'react-bootstrap/Container';
import { ROLES_PERMISSIONS_MESSAGES } from '../../../lib/constants';
import RolePermissionsDashboard from './RolePermissionsDashboard';
import PermissionsTeamsTable from '../../../components/Tables/PermissionsTeamsTable';
import { useAppDispatch } from '../../../context/store';
import { getPermissionsTeamsAsync } from '../../../slices/admin/rolePermissionsSlice';

const Admins = (): JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getPermissionsTeamsAsync('admin'));
    }, []);
    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <div id='Default'>
                    <label className='roleMessageLabel'>{ROLES_PERMISSIONS_MESSAGES.ADMINS_TAB_MSG}</label>
                </div>
                <RolePermissionsDashboard tabName='admin' />
                <div className='rolePermissionTable'>
                    <PermissionsTeamsTable tabName='admin' />
                    <div className='mt-5'>
                        <p>{ROLES_PERMISSIONS_MESSAGES.FOOTER_NOTE_ASSOCIATED}</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};
export default Admins;
