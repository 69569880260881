import React from 'react';
import { store } from './context/store';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PERMISSIONS_CONTEXT from 'GuardianPermissions/lib/config/permissionsContext';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import { connect } from 'react-redux';
import { DOMAINS } from './lib/constants';
import { PermissionsContext, UserContext, ComposedWithAuthAndPermissionsProps } from './lib/types';
import { compose } from 'redux';

const App = ({ permissionsContext }: ComposedWithAuthAndPermissionsProps): JSX.Element => {
    return (
        <DimmedLoader
            active={
                permissionsContext.isFetchingPermissions[DOMAINS.DEFAULT] ||
                !permissionsContext.initialized[DOMAINS.DEFAULT]
            }
        >
            <Provider store={store}>
                <ToastContainer
                    position='top-right'
                    autoClose={20000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <BrowserRouter>
                    <AppRoutes />
                </BrowserRouter>
            </Provider>
        </DimmedLoader>
    );
};

const mapPermissionStateToProps = (state: PermissionsContext): PermissionsContext => {
    const { permissionsContext } = state;
    return {
        permissionsContext
    };
};

const mapAuthStateToProps = (state: UserContext): UserContext => {
    const { userContext } = state;
    return {
        userContext
    };
};

export default compose(
    connect(mapPermissionStateToProps, null, null, { context: PERMISSIONS_CONTEXT }),
    connect(mapAuthStateToProps, null, null, { context: AUTH_CONTEXT })
)(App) as React.ComponentType;
