import React from 'react';
import '../../css/Admin.css';
import { AvailableRoutes } from '../../AppRoutes';
import { Redirect } from 'react-router-dom';

const DataSynchronization = (): JSX.Element => {
    return <Redirect to={AvailableRoutes.gsocCallDirectory} />;
};

export default DataSynchronization;
