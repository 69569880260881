import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { AvailableRoutes } from '../../AppRoutes';
import { FaUser } from 'react-icons/fa';
import { MdLock, MdSync } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import '../../css/Admin.css';

const styles = {
    adminSidebarMainLabel: {
        color: '#000000'
    },
    adminSidebarMainLabelActive: {
        fontSize: '12pt',
        fontWeight: '500',
        color: '#000000'
    },
    adminSidebarSubLabel: {
        color: '#000000',
        fontSize: '10pt',
        fontWeight: '350'
    },
    adminSidebarSubLabelActive: {
        fontSize: '10pt',
        fontWeight: '400',
        color: '#0D4AFF'
    }
};

const AdminSidebar = (): JSX.Element => {
    return (
        <Nav className='AdminSidebar flex-column'>
            <Nav.Item className='mt-3'>
                <Nav.Link
                    className='ml-n2'
                    as={NavLink}
                    to={AvailableRoutes.dataAnonymization}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarMainLabelActive}
                >
                    <MdLock className='sidebarIcon' />
                    Data Anonymization
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.incidentAnonymization}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    Event History
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.phoneAnonymization}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    Phone Number
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className='mt-3'>
                <Nav.Link
                    className='ml-n2'
                    as={NavLink}
                    to={AvailableRoutes.rolesAndPermissions}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarMainLabelActive}
                >
                    <FaUser className='sidebarIcon' />
                    Roles & Permissions
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.usersMenu}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    Users
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.eventOwnersMenu}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    Event Owners
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.businessTeamsMenu}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    Business Teams
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.adminsMenu}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    Admins
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className='mt-3'>
                <Nav.Link
                    className='ml-n2'
                    as={NavLink}
                    to={AvailableRoutes.dataSynchronization}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarMainLabelActive}
                >
                    <MdSync className='sidebarIcon' />
                    Data Synchronization
                </Nav.Link>
                <Nav.Link
                    className='ml-4'
                    as={NavLink}
                    to={AvailableRoutes.gsocCallDirectory}
                    style={styles.adminSidebarMainLabel}
                    activeStyle={styles.adminSidebarSubLabelActive}
                >
                    GSOC Call Directory
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default AdminSidebar;
