import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../context/store';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import { toast } from 'react-toastify';
import {
    emptyUserCardholderInformation,
    UserCardholderInformation,
    UserRoleInformation
} from '../../lib/types';
import {
    GET_CARDHOLDERS_API_ENDPOINT,
    GET_ROLE_BY_LOGINS_ENDPOINT,
    SEARCH_LOGINS_API_ENDPOINT,
    GET_LATEST_APP_VERSION_ENDPOINT,
    GET_FACILITY_LIST_ENDPOINT,
    SEARCH_CARDHOLDERS_API_ENDPOINT
} from '../../lib/globals';
import {
    ERROR_AUTO_CLOSE,
    INVALID_LOGIN,
    MAX_CARDHOLDERS_PAGE_SIZE,
    ACTIVE_GROUP_EMP_STATUS
} from '../../lib/constants';

const { doRequest, checkErrorResponse } = asyncRequest;

interface Site {
    value: string;
    label: string;
}

interface CardholderRequest {
    pageSize: number;
    pageNumber: number;
    exactMatch: boolean;
    searchAttributes: {
        location: string;
        groupedEmpStatus: string;
    };
}

interface UserProfileState {
    userCardholderInformation: UserCardholderInformation;
    isGettingUserCardholderInformation: boolean;
    searchUserCardholderInformationError: string;
    searchedLogins: UserCardholderInformation[];
    isSearchingLogins: boolean;
    searchLoginsError: string;
    roleByLogins: { resolvedUsers: UserRoleInformation[]; failedUsers: UserRoleInformation[] };
    isGettingRoleByLogins: boolean;
    getRoleByLoginsError: string;
    latestAppVersion: string;
    isGettingLatestAppVersion: boolean;
    getLatestAppVersionError: string;
    userSites: Site[];
    isGettingUserSites: boolean;
    getUserSitesError: string;
}

export const initialState: UserProfileState = {
    userCardholderInformation: emptyUserCardholderInformation(),
    isGettingUserCardholderInformation: false,
    searchUserCardholderInformationError: '',
    searchedLogins: [],
    isSearchingLogins: false,
    searchLoginsError: '',
    roleByLogins: { resolvedUsers: [], failedUsers: [] },
    isGettingRoleByLogins: false,
    getRoleByLoginsError: '',
    latestAppVersion: '',
    isGettingLatestAppVersion: false,
    getLatestAppVersionError: '',
    userSites: [],
    isGettingUserSites: false,
    getUserSitesError: ''
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        startSearchingUserCardholderInformation: (state) => {
            state.userCardholderInformation = initialState.userCardholderInformation;
            state.searchUserCardholderInformationError = initialState.searchUserCardholderInformationError;
            state.isGettingUserCardholderInformation = true;
        },
        searchUserCardholderInformationSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                userCardholderInformation: UserCardholderInformation;
            }>
        ) => {
            const { userCardholderInformation } = payload;
            state.isGettingUserCardholderInformation = false;
            state.userCardholderInformation = userCardholderInformation;
        },
        searchUserCardholderInformationFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingUserCardholderInformation = false;
            state.searchUserCardholderInformationError = error;
        },
        startSearchLogins: (state) => {
            state.searchedLogins = initialState.searchedLogins;
            state.searchLoginsError = initialState.searchLoginsError;
            state.isSearchingLogins = true;
        },
        searchLoginsSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                searchedLogins: UserCardholderInformation[];
            }>
        ) => {
            const { searchedLogins } = payload;
            state.isSearchingLogins = false;
            state.searchedLogins = searchedLogins;
        },
        searchLoginsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isSearchingLogins = false;
            state.searchLoginsError = error;
        },
        startGetRoleByLogins: (state) => {
            state.roleByLogins = initialState.roleByLogins;
            state.getRoleByLoginsError = initialState.getRoleByLoginsError;
            state.isGettingRoleByLogins = true;
        },
        getRoleByLoginsSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                resolvedUsers: UserRoleInformation[];
                failedUsers: UserRoleInformation[];
            }>
        ) => {
            const { resolvedUsers, failedUsers } = payload;
            state.isGettingRoleByLogins = false;
            state.roleByLogins = { resolvedUsers, failedUsers };
        },
        getRoleByLoginsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingRoleByLogins = false;
            state.getRoleByLoginsError = error;
        },
        resetRoleByLogins: (state) => {
            state.roleByLogins = initialState.roleByLogins;
            state.getRoleByLoginsError = initialState.getRoleByLoginsError;
            state.isGettingRoleByLogins = false;
        },
        startGetLatestAppVersion: (state) => {
            state.latestAppVersion = initialState.latestAppVersion;
            state.getLatestAppVersionError = initialState.getLatestAppVersionError;
            state.isGettingLatestAppVersion = true;
        },
        getLatestAppVersionSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                latestAppVersion: string;
            }>
        ) => {
            const { latestAppVersion } = payload;
            state.isGettingLatestAppVersion = false;
            state.latestAppVersion = latestAppVersion;
        },
        getLatestAppVersionFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingLatestAppVersion = false;
            state.getLatestAppVersionError = error;
        },
        startGetUserSites: (state) => {
            state.userSites = initialState.userSites;
            state.getUserSitesError = initialState.getUserSitesError;
            state.isGettingUserSites = true;
        },
        getUserSitesSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                userSites: Site[];
            }>
        ) => {
            const { userSites } = payload;
            state.isGettingUserSites = false;
            state.userSites = userSites;
        },
        getUserSitesFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingUserSites = false;
            state.getUserSitesError = error;
        }
    }
});

export const {
    startSearchingUserCardholderInformation,
    searchUserCardholderInformationSucceeded,
    searchUserCardholderInformationFailed,
    startSearchLogins,
    searchLoginsSucceeded,
    searchLoginsFailed,
    startGetRoleByLogins,
    getRoleByLoginsSucceeded,
    getRoleByLoginsFailed,
    resetRoleByLogins,
    startGetLatestAppVersion,
    getLatestAppVersionSucceeded,
    getLatestAppVersionFailed,
    startGetUserSites,
    getUserSitesSucceeded,
    getUserSitesFailed
} = userProfileSlice.actions;

export default userProfileSlice.reducer;

export const searchUserCardholderInformationAsync = (empId: string): AppThunk => async (dispatch) => {
    try {
        dispatch(startSearchingUserCardholderInformation());
        const cardholderSearchRequest = {
            empIds: [empId]
        };
        const response = await doRequest(
            GET_CARDHOLDERS_API_ENDPOINT,
            cardholderSearchRequest,
            GuardianAuth.createRequestAuthHeader()
        );
        const userCardholderInformation: UserCardholderInformation = response?.data?.results[0]
            ? response.data.results[0]
            : undefined;
        if (!userCardholderInformation) {
            throw new Error('Invalid Employee ID');
        }
        dispatch(
            searchUserCardholderInformationSucceeded({
                userCardholderInformation
            })
        );
    } catch (error) {
        dispatch(searchUserCardholderInformationFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Searching cardholder details failed'} errorMessage={error.message} />
        );
    }
};

export const searchLoginsAsync = (login: string): AppThunk => async (dispatch) => {
    try {
        dispatch(startSearchLogins());
        const request = {
            login
        };
        const response = await doRequest(
            SEARCH_LOGINS_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        const parsedResponse = JSON.parse(response?.data?.body);
        if (!parsedResponse || !parsedResponse.result) {
            throw new Error('Invalid searchLogins response!');
        }
        const { result } = parsedResponse;
        if (result && result.length < 1) {
            result.login = login;
            result.status = INVALID_LOGIN;
        }
        const sortedResult = result.sort((a: UserCardholderInformation, b: UserCardholderInformation) =>
            a.login.localeCompare(b.login)
        );
        dispatch(
            searchLoginsSucceeded({
                searchedLogins: sortedResult
            })
        );
        return sortedResult;
    } catch (error) {
        dispatch(searchLoginsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Failed to search for logins.'} errorMessage={error.message} />
        );
    }
};

export const getRoleByLoginsAsync = (logins: string[]): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetRoleByLogins());
        if (!logins || !logins.length) {
            throw new Error('Logins array must not be empty!');
        }
        const request = {
            logins
        };
        const response = await doRequest(
            GET_ROLE_BY_LOGINS_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        const parsedResponse = JSON.parse(response?.data?.body);
        if (!parsedResponse || !parsedResponse.resolvedUsers || !parsedResponse.failedUsers) {
            throw new Error('Invalid getRoleByLogins response!');
        }
        const { resolvedUsers, failedUsers } = parsedResponse;
        dispatch(
            getRoleByLoginsSucceeded({
                resolvedUsers,
                failedUsers
            })
        );
    } catch (error) {
        dispatch(getRoleByLoginsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Failed to get role by logins'} errorMessage={error.message} />
        );
    }
};

export const getLatestAppVersionAsync = (): AppThunk => async (dispatch) => {
    const DEFAULT_PLATFORM = 'IOS';
    const DEFAULT_CURRENT_VERSION = '1.0.0';
    try {
        dispatch(startGetLatestAppVersion());
        const request = {
            platform: DEFAULT_PLATFORM,
            currentVersion: DEFAULT_CURRENT_VERSION
        };
        const response = await doRequest(
            GET_LATEST_APP_VERSION_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        const parsedResponse = JSON.parse(response?.data?.body);
        if (!parsedResponse || !parsedResponse.latestVersion) {
            throw new Error('Invalid appUpdateCheck response!');
        }
        const { latestVersion } = parsedResponse;
        dispatch(
            getLatestAppVersionSucceeded({
                latestAppVersion: latestVersion
            })
        );
    } catch (error) {
        dispatch(getLatestAppVersionFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Failed to get latest app version.'} errorMessage={error.message} />
        );
    }
};

export const getUserSites = (): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetUserSites());
        let userSites: Site[] = [];
        const response = await doRequest(
            GET_FACILITY_LIST_ENDPOINT,
            null,
            GuardianAuth.createRequestAuthHeader()
        );
        const responseData = response?.data;
        if (responseData && responseData.length) {
            userSites = responseData.map((site: string) => ({ value: site, label: site }));
        }
        dispatch(getUserSitesSucceeded({ userSites }));
    } catch (error) {
        const errorMessage = checkErrorResponse(error);
        dispatch(getUserSitesFailed({ error: errorMessage }));
        toast.error(
            <ToastErrorMessage header={'Failed to load facility list.'} errorMessage={errorMessage} />,
            {
                autoClose: ERROR_AUTO_CLOSE
            }
        );
    }
};

const createCardholderRequest = (siteName: string, pageNumber?: number): CardholderRequest => {
    return {
        pageSize: MAX_CARDHOLDERS_PAGE_SIZE,
        pageNumber: pageNumber ? pageNumber : 1,
        exactMatch: true,
        searchAttributes: {
            location: siteName,
            groupedEmpStatus: ACTIVE_GROUP_EMP_STATUS
        }
    };
};

const processCardholderResponse = (response: any): [string[], number | undefined] => {
    const logins: string[] = [];
    const cardholders: UserCardholderInformation[] = response?.data?.employees;
    const totalCount: number | undefined = response?.data?.totalCount;
    if (cardholders?.length) {
        cardholders.forEach((cardholder: UserCardholderInformation) => {
            if (cardholder?.login) {
                logins.push(cardholder.login);
            }
        });
    }
    return [logins, totalCount];
};

export const getRoleBySitesAsync = (siteNames: string[]): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetRoleByLogins());
        const searchCardholdersPromises: Promise<any>[] = [];
        const additionalSearchCardholdersPromises: Promise<any>[] = [];
        siteNames.forEach((siteName) => {
            searchCardholdersPromises.push(
                doRequest(
                    SEARCH_CARDHOLDERS_API_ENDPOINT,
                    createCardholderRequest(siteName),
                    GuardianAuth.createRequestAuthHeader()
                )
            );
        });
        const logins: string[] = [];
        const cardholderResponses = await Promise.all(searchCardholdersPromises);
        if (cardholderResponses?.length) {
            for (let i = 0; i < cardholderResponses.length; i++) {
                const response = cardholderResponses[i];
                const [currentLogins, totalCount] = processCardholderResponse(response);
                logins.push(...currentLogins);
                if (totalCount && totalCount > MAX_CARDHOLDERS_PAGE_SIZE) {
                    // make additional calls to get remaining cardholders
                    const totalPages = Math.ceil(totalCount / MAX_CARDHOLDERS_PAGE_SIZE);
                    for (let currentPage = 2; currentPage <= totalPages; currentPage++) {
                        // start from page 2
                        additionalSearchCardholdersPromises.push(
                            doRequest(
                                SEARCH_CARDHOLDERS_API_ENDPOINT,
                                createCardholderRequest(siteNames[i], currentPage),
                                GuardianAuth.createRequestAuthHeader()
                            )
                        );
                    }
                }
            }
        }
        const additionalCardholderResponses = await Promise.all(additionalSearchCardholdersPromises);
        if (additionalCardholderResponses?.length) {
            additionalCardholderResponses.forEach((response) => {
                const [currentLogins] = processCardholderResponse(response);
                logins.push(...currentLogins);
            });
        }
        if (logins.length) {
            dispatch(getRoleByLoginsAsync(Array.from(new Set(logins)))); // deduplicate logins
        } else {
            dispatch(
                getRoleByLoginsSucceeded({
                    resolvedUsers: [],
                    failedUsers: []
                })
            );
        }
    } catch (error) {
        dispatch(getRoleByLoginsFailed);
        toast.error(
            <ToastErrorMessage header={'Failed to get user roles by sites!'} errorMessage={error.message} />
        );
    }
};
