import React, { useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { validateLoginsAsync } from '../lib/helpers/helpers';
import { useAsyncAbortable } from 'react-async-hook';
import { ValidatedLogins } from '../lib/types';

type ResultBox<T> = { v: T };

function useConstant<T>(fn: () => T): T {
    const ref = React.useRef<ResultBox<T>>();

    if (!ref.current) {
        ref.current = { v: fn() };
    }

    return ref.current.v;
}

const useValidatedLogins = (): {
    logins: string;
    setLogins: (login: string) => void;
    validatedLogins: ValidatedLogins;
} => {
    const [logins, setLogins] = useState('');
    const loginArray = logins
        .split(',')
        .map((s: string) => s.trim())
        .filter((s: string) => s);

    const debounceValidatedLogin = useConstant(() => AwesomeDebouncePromise(validateLoginsAsync, 600));

    const { result } = useAsyncAbortable(
        async (abortSignal, text) => {
            if (text === undefined || !text.length) {
                return { validLogins: [], invalidLogins: [], validCardholders: undefined };
            } else {
                return debounceValidatedLogin(loginArray);
            }
        },
        [logins]
    );

    return {
        logins,
        setLogins,
        validatedLogins: result ? result : { validLogins: [], invalidLogins: [], validCardholders: undefined }
    };
};

export default useValidatedLogins;
