import { combineReducers } from '@reduxjs/toolkit';
import incidentsReducer from '../slices/incidents/incidentSlice';
import incidentDetailsReducer from '../slices/incidents/incidentDetailsSlice';
import incidentsHistoryReducer from '../slices/incidentsHistory/IncidentsHistorySlice';
import userProfileReducer from '../slices/userProfile/userProfileSlice';
import userPermissionsReducer from '../slices/permissions/userPermissionsSlice';
import downloadAppReducer from '../slices/device/downloadAppSlice';
import dataAnonymizationReducer from '../slices/admin/dataAnonymizationSlice';
import rolePermissionsDashboardReducer from '../slices/admin/rolePermissionsSlice';
import facManKeysReducer from '../slices/admin/facManKeysSlice';
import responderDashboardReducer from '../slices/responderDashboard/responderDashboardSlice';
import contactsReducer from '../slices/contact/contactsSlice';

const rootReducer = combineReducers({
    incidents: incidentsReducer,
    incidentDetails: incidentDetailsReducer,
    incidentsHistory: incidentsHistoryReducer,
    userProfile: userProfileReducer,
    downloadApp: downloadAppReducer,
    dataAnonymization: dataAnonymizationReducer,
    userPermissions: userPermissionsReducer,
    rolePermissions: rolePermissionsDashboardReducer,
    facManKeys: facManKeysReducer,
    responderDashboard: responderDashboardReducer,
    contacts: contactsReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
