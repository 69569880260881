import React from 'react';
import '../../../css/Admin.css';
import AdminSidebar from '../../../components/Sidebar/AdminSidebar';
import RolePermissionsDashboard from './RolePermissionsDashboard';
import Container from 'react-bootstrap/Container';
import { ROLES_PERMISSIONS_MESSAGES } from '../../../lib/constants';

const Users = (): JSX.Element => {
    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <div id='Default'>
                    <label className='roleMessageLabel'>{ROLES_PERMISSIONS_MESSAGES.USERS_TAB_MSG}</label>
                </div>
                <RolePermissionsDashboard tabName='user' />
                <div>
                    <p style={{ marginTop: '15%', marginLeft: '1%' }}>
                        {ROLES_PERMISSIONS_MESSAGES.FOOTER_NOTE_ASSOCIATED}
                    </p>
                </div>
            </div>
        </Container>
    );
};
export default Users;
