import React, { useEffect } from 'react';
import '../../../css/Admin.css';
import AdminSidebar from '../../../components/Sidebar/AdminSidebar';
import Container from 'react-bootstrap/Container';
import { ROLES_PERMISSIONS_MESSAGES, EVENT_OWNERS_FOOTNOTES } from '../../../lib/constants';
import RolePermissionsDashboard from './RolePermissionsDashboard';
import PermissionsTeamsTable from '../../../components/Tables/PermissionsTeamsTable';
import FacManKeysTable from '../../../components/Tables/FacManKeysTable';
import { useAppDispatch } from '../../../context/store';
import { getPermissionsTeamsAsync } from '../../../slices/admin/rolePermissionsSlice';
import { getFacManKeysAsync } from '../../../slices/admin/facManKeysSlice';

const EventOwners = (): JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getPermissionsTeamsAsync('eventOwner'));
        dispatch(getFacManKeysAsync());
    }, []);
    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <div id='Default'>
                    <label className='roleMessageLabel'>
                        {ROLES_PERMISSIONS_MESSAGES.EVENT_OWNERS_TAB_MSG}
                    </label>
                </div>
                <RolePermissionsDashboard tabName='eventOwner' />
                <div className='rolePermissionTable'>
                    <div className='mt-n5'>
                        <PermissionsTeamsTable tabName='eventOwner' />
                    </div>
                    <div className='mt-n5'>
                        <FacManKeysTable />
                    </div>
                </div>
                <div id='Default' className='ml-3 mt-n5'>
                    <label className='mt-5 mb-4' style={{ fontSize: '1.2em' }}>
                        {EVENT_OWNERS_FOOTNOTES.TITLE}
                    </label>
                    <p className='mb-0'>{EVENT_OWNERS_FOOTNOTES.NOTE1}</p>
                    <p className='mb-0'>{EVENT_OWNERS_FOOTNOTES.NOTE2}</p>
                    <div>
                        <p className='mt-5'>{ROLES_PERMISSIONS_MESSAGES.FOOTER_NOTE_ASSOCIATED}</p>
                    </div>
                </div>
            </div>
        </Container>
    );
};
export default EventOwners;
