export const DataAnonymizationTitlePropMap = {
    country: 'Country',
    time: 'Time',
    eventTypes: 'EventType(s)',
    dateLastModified: 'Date Last Modified',
    user: 'User'
};

export const DataAnonymizationPropMap = {
    country: 'country',
    time: 'time',
    eventTypes: 'eventTypes',
    dateLastModified: 'dateLastModified',
    user: 'user'
};

export const DataAnonymizationFilterPropMap: { [key: string]: string } = {
    country: 'country',
    time: 'time',
    dateLastModified: 'dateLastModified',
    user: 'user'
};

export const DataAnonymizationSortPropMap: { [key: string]: string } = {
    ...DataAnonymizationFilterPropMap,
    country: 'country.countryName'
};
