import React, { Fragment } from 'react';
import NavBar from './views/NavBar';
import { Route, Switch } from 'react-router-dom';
import Admin from './views/admin/Admin';
import Profile from './views/profile/Profile';
import Incidents from './views/incidents/Incidents';
import IncidentHistory from './views/incidentHistory/IncidentHistory';
import IncidentDetails from './views/incidents/IncidentDetails';
import RolesPermissions from './views/admin/RolesPermissions';
import { DataAnonymization, Anonymization } from './views/admin/DataAnonymization';
import GuardianOnsiteRosterWidget from 'GuardianOnsiteRosterWidget/lib/';
import Users from './views/admin/roles/Users';
import EventOwners from './views/admin/roles/EventOwners';
import BusinessTeams from './views/admin/roles/BusinessTeams';
import Admins from './views/admin/roles/Admins';
import NoPermissionsViews from './views/NoPermissionsView';
import { useSelector } from 'react-redux';
import { RootState } from './context/rootReducer';
import PermissionsLookup from './views/profile/PermissionsLookup';
import OnsiteResponderDashboard from './views/onSiteEmergencyResponders/OnsiteResponderDashboard';
import DataSynchronization from './views/admin/DataSynchronization';
import { GsocCallDirectory } from './views/admin/GsocCallDirectory';
import { Contact } from './views/contact/Contact';

export const AvailableRoutes = {
    incidents: '/',
    incidentHistory: '/eventHistory',
    onSiteRoster: '/osrw',
    onSiteEmergencyResponders: '/oser',
    incidentDetails: '/eventDetails/:incidentId',
    dataAnonymization: '/admin/DataAnonymization',
    profile: '/profile/myPermissions',
    contact: '/contact',
    incidentAnonymization: '/admin/DataAnonymization/EventHistory',
    phoneAnonymization: '/admin/DataAnonymization/PhoneNumber',
    rolesAndPermissions: '/admin/RolesPermissions',
    usersMenu: '/admin/RolesPermissions/Users',
    eventOwnersMenu: '/admin/RolesPermissions/EventOwners',
    businessTeamsMenu: '/admin/RolesPermissions/BusinessTeams',
    adminsMenu: '/admin/RolesPermissions/Admins',
    dataSynchronization: '/admin/DataSynchronization',
    gsocCallDirectory: '/admin/DataSynchronization/GsocCallDirectory',
    admin: '/admin',
    userLookup: '/profile/userLookup',
    siteLookup: '/profile/siteLookup'
};

const AppRoutes = (): JSX.Element => {
    const { showOSR, showIncidentDetails, showIncidentHistory, showAdmin } = useSelector(
        (state: RootState) => state.userPermissions?.userPermissionActions
    );

    return (
        <Fragment>
            <NavBar />
            <Switch>
                <Route
                    exact
                    path={AvailableRoutes.incidents}
                    render={() => (
                        <Fragment>
                            <Incidents />
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.incidentHistory}
                    render={() => (
                        <Fragment>
                            {showIncidentHistory ? <IncidentHistory /> : <NoPermissionsViews />}
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.onSiteRoster}
                    render={() => (
                        <Fragment>
                            {showOSR ? <GuardianOnsiteRosterWidget /> : <NoPermissionsViews />}
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.onSiteEmergencyResponders}
                    render={() => (
                        <Fragment>{showOSR ? <OnsiteResponderDashboard /> : <NoPermissionsViews />}</Fragment>
                    )}
                />
                <Route
                    path={AvailableRoutes.incidentDetails}
                    render={() => (
                        <Fragment>
                            {showIncidentDetails ? <IncidentDetails /> : <NoPermissionsViews />}
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.profile}
                    render={() => (
                        <Fragment>
                            <Profile />
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.contact}
                    render={() => (
                        <Fragment>
                            <Contact />
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.admin}
                    render={() => <Fragment>{showAdmin ? <Admin /> : <NoPermissionsViews />}</Fragment>}
                />
                <Route
                    exact
                    path={AvailableRoutes.dataAnonymization}
                    render={() => (
                        <Fragment>{showAdmin ? <DataAnonymization /> : <NoPermissionsViews />}</Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.incidentAnonymization}
                    render={() => (
                        <Fragment>
                            {showAdmin ? (
                                <Anonymization isIncidentAnonymization={true} />
                            ) : (
                                <NoPermissionsViews />
                            )}
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.phoneAnonymization}
                    render={() => (
                        <Fragment>
                            {showAdmin ? (
                                <Anonymization isPhoneAnonymization={true} />
                            ) : (
                                <NoPermissionsViews />
                            )}
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.rolesAndPermissions}
                    render={() => (
                        <Fragment>{showAdmin ? <RolesPermissions /> : <NoPermissionsViews />}</Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.usersMenu}
                    render={() => <Fragment>{showAdmin ? <Users /> : <NoPermissionsViews />}</Fragment>}
                />
                <Route
                    exact
                    path={AvailableRoutes.eventOwnersMenu}
                    render={() => <Fragment>{showAdmin ? <EventOwners /> : <NoPermissionsViews />}</Fragment>}
                />
                <Route
                    exact
                    path={AvailableRoutes.businessTeamsMenu}
                    render={() => (
                        <Fragment>{showAdmin ? <BusinessTeams /> : <NoPermissionsViews />}</Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.adminsMenu}
                    render={() => <Fragment>{showAdmin ? <Admins /> : <NoPermissionsViews />}</Fragment>}
                />
                <Route
                    exact
                    path={AvailableRoutes.dataSynchronization}
                    render={() => (
                        <Fragment>{showAdmin ? <DataSynchronization /> : <NoPermissionsViews />}</Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.gsocCallDirectory}
                    render={() => (
                        <Fragment>{showAdmin ? <GsocCallDirectory /> : <NoPermissionsViews />}</Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.userLookup}
                    render={() => (
                        <Fragment>
                            <PermissionsLookup isUserLookup={true} />
                        </Fragment>
                    )}
                />
                <Route
                    exact
                    path={AvailableRoutes.siteLookup}
                    render={() => (
                        <Fragment>
                            <PermissionsLookup isSiteLookup={true} />
                        </Fragment>
                    )}
                />
            </Switch>
        </Fragment>
    );
};

export default AppRoutes;
