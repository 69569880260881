import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridPagination,
    GridFilterModel,
    GridSortModel,
    GridSortDirection
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import {
    downloadRosterCSVAsync,
    downloadFilteredRosterCSVAsync
} from '../../slices/incidentsHistory/IncidentsHistorySlice';
import { useAppDispatch } from '../../context/store';
import { RootState } from '../../context/rootReducer';
import { OnSiteRosterPropMap } from '../../lib/dictionaries/IncidentDetailsOnSiteRosterDictionary';
import { searchIncidentRosterAsync } from '../../slices/incidents/incidentDetailsSlice';
import { useTimeoutFn } from 'react-use';
import { formatToLocalTime } from '../../lib/helpers/helpers';
import { DateTime } from 'luxon';
import ToggleAutoReloadSwitch from '../Buttons/ToggleAutoReloadSwitch';
import {
    onSiteRosterColumns,
    defaultSortModel,
    buttonBaseProps,
    DEFAULT_PAGE_SIZE
} from './IncidentDetailsOnSiteRosterProps';
import { RosterFilterAttributes } from '../../lib/types';
import {
    DATA_GRID_COMMON_COMPONENTS_PROPS_STYLES,
    DATA_GRID_COMMON_SX_STYLES,
    DEFAULT_TABLE_SORTING_ORDER,
    SIZE_PER_PAGE_LIST,
    START_PAGE,
    TABLE_REFRESH_TIME,
    TABLE_FILTER_TIP,
    FILTER_TIP_ICON_SIZE,
    RP_BLUE
} from '../../lib/constants';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Logger } from '../../lib/utils/logger';

const styles = {
    tableFilterTip: {
        fontSize: '12px',
        fontWeight: '400',
        marginBottom: '10px'
    },
    tableFilterButton: {
        border: 'none',
        marginLeft: '5px',
        marginBottom: '9px',
        background: 'none'
    }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const IncidentDetailsOnSiteRosterDataGrid: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { incidentId } = useParams<{ incidentId: string }>();
    const [timestamp, setTimestamp] = useState(formatToLocalTime(DateTime.utc()));
    const [selectedPageSize, setSelectedPageSize] = React.useState(DEFAULT_PAGE_SIZE);
    const [page, setPage] = React.useState(START_PAGE);
    const [filters, setFilters] = React.useState<GridFilterModel>();
    const [filterTip, setFilterTip] = React.useState(false);
    const [tableSort, setTableSort] = React.useState<GridSortModel>(defaultSortModel);
    const {
        roster,
        isSearchingIncidentRoster,
        currentRosterSearch: { totalCount, sortOrder, filterAttributes }
    } = useSelector((state: RootState) => state.incidentDetails);
    const { incident } = useSelector((state: RootState) => state.incidentDetails);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);

    const refreshTable = (): void => {
        dispatch(
            searchIncidentRosterAsync({
                incidentId,
                pageSize: selectedPageSize,
                pageNumber: page,
                sortOrder,
                filterAttributes
            })
        );
    };

    const [isReady, cancel, reset] = useTimeoutFn(refreshTable, TABLE_REFRESH_TIME);

    if (isReady()) {
        reset();
    }

    useEffect(() => {
        if (incidentId) {
            dispatch(searchIncidentRosterAsync({ incidentId, pageSize: selectedPageSize, pageNumber: page }));
        }
    }, []);

    useEffect(() => {
        setTimestamp(formatToLocalTime(DateTime.utc()));
    }, [roster]);

    useEffect(() => {
        tableFilterAndSortHandler();
    }, [filters, tableSort]);

    useEffect(() => {
        refreshTable();
    }, [page, selectedPageSize]);

    const tableFilterAndSortHandler = (): void => {
        const filterAttributes: any = {} as RosterFilterAttributes;
        if (filters) {
            const { items } = filters;
            items.forEach(({ columnField, value }) => {
                if (value && value !== '') {
                    if (columnField === OnSiteRosterPropMap.lastName) {
                        if (filterAttributes[OnSiteRosterPropMap.name]) {
                            if (filterAttributes[OnSiteRosterPropMap.name][OnSiteRosterPropMap.lastName]) {
                                filterAttributes[OnSiteRosterPropMap.name][OnSiteRosterPropMap.lastName].push(
                                    value
                                );
                            } else {
                                filterAttributes[OnSiteRosterPropMap.name][OnSiteRosterPropMap.lastName] = [
                                    value
                                ];
                            }
                        } else {
                            filterAttributes[OnSiteRosterPropMap.name] = {
                                [OnSiteRosterPropMap.lastName]: [value]
                            };
                        }
                    } else if (columnField === OnSiteRosterPropMap.firstName) {
                        if (filterAttributes[OnSiteRosterPropMap.name]) {
                            if (filterAttributes[OnSiteRosterPropMap.name][OnSiteRosterPropMap.firstName]) {
                                filterAttributes[OnSiteRosterPropMap.name][
                                    OnSiteRosterPropMap.firstName
                                ].push(value);
                            } else {
                                filterAttributes[OnSiteRosterPropMap.name][OnSiteRosterPropMap.firstName] = [
                                    value
                                ];
                            }
                        } else {
                            filterAttributes[OnSiteRosterPropMap.name] = {
                                [OnSiteRosterPropMap.firstName]: [value]
                            };
                        }
                    } else if (columnField === OnSiteRosterPropMap.accountedFor) {
                        if (filterAttributes[columnField]) {
                            filterAttributes[columnField].push(value === 'Yes' ? 1 : 0);
                        } else {
                            filterAttributes[columnField] = [value === 'Yes' ? 1 : 0];
                        }
                    } else {
                        if (filterAttributes[columnField]) {
                            filterAttributes[columnField].push(value);
                        } else {
                            filterAttributes[columnField] = [value];
                        }
                    }
                }
            });
        }
        const sort = {
            column: '',
            order: ''
        };
        if (tableSort && tableSort.length) {
            const sortField = tableSort[0].field;
            if (sortField === OnSiteRosterPropMap.accountedBy) {
                sort.column = `${sortField}.login`;
            } else if (sortField === OnSiteRosterPropMap.type) {
                sort.column = `lastCheckInMethodId`;
            } else if (sortField === OnSiteRosterPropMap.name) {
                sort.column = 'firstName';
            } else {
                sort.column = sortField;
            }
        } else {
            sort.column = OnSiteRosterPropMap.accountedFor;
        }
        if (tableSort && tableSort[0] && tableSort[0].sort === 'asc') {
            sort.order = 'desc'; // intentionally mismatched so down sort arrow orders DESC
        } else {
            sort.order = 'asc';
        }
        dispatch(
            searchIncidentRosterAsync({
                incidentId,
                pageSize: selectedPageSize,
                pageNumber: page,
                sortOrder: [sort],
                filterAttributes
            })
        );
    };

    const tableFilterChangeCallback = (newFilters: GridFilterModel): void => {
        if (newFilters !== filters) {
            setPage(START_PAGE);
            setFilters(newFilters);
        }
    };

    const tableSortChangeCallback = (newSort: GridSortModel): void => {
        setPage(START_PAGE);
        setTableSort(newSort);
    };

    const tablePageHandlerCallback = (newPage: number): void => {
        newPage < START_PAGE ? setPage(START_PAGE) : setPage(newPage + START_PAGE);
    };

    const tablePageSizeChange = (event: any): void => {
        setPage(START_PAGE);
        setSelectedPageSize(event);
    };

    const CustomToolbar = (props: any): JSX.Element => {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton {...props} />
                <GridToolbarDensitySelector {...props} />
                <GridToolbarColumnsButton {...props} />
                <Button
                    {...buttonBaseProps}
                    onClick={async () => {
                        dispatch(downloadRosterCSVAsync(incidentId));
                        Logger.info(
                            `${userCardholderInformation.login} is initiating a Full Export download of the Onsite Roster`,
                            {
                                employeeId: userCardholderInformation.empId,
                                userAlias: userCardholderInformation.login,
                                incidentId: incident?.id,
                                siteNames: incident?.sites
                            }
                        );
                    }}
                >
                    Full Export
                </Button>
                <Button
                    {...buttonBaseProps}
                    onClick={async () => {
                        dispatch(downloadFilteredRosterCSVAsync(incidentId, sortOrder, filterAttributes));
                        Logger.info(
                            `${userCardholderInformation.login} is initiating a Filtered Export download of the Onsite Roster`,
                            {
                                employeeId: userCardholderInformation.empId,
                                userAlias: userCardholderInformation.login,
                                incidentId: incident?.id,
                                siteNames: incident?.sites,
                                filterAttributes: filterAttributes
                            }
                        );
                    }}
                >
                    Filtered Export
                </Button>
            </GridToolbarContainer>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h6>On-Site Roster as of {timestamp} </h6>
                <button onClick={() => setFilterTip(!filterTip)} style={styles.tableFilterButton}>
                    <AiOutlineInfoCircle size={FILTER_TIP_ICON_SIZE} color={RP_BLUE} />
                </button>
                {filterTip && <i style={styles.tableFilterTip}>{TABLE_FILTER_TIP}</i>}
                <div className='ml-auto'>
                    <ToggleAutoReloadSwitch
                        label='For Table'
                        cancel={() => {
                            cancel();
                        }}
                        reset={() => {
                            reset();
                        }}
                    />
                </div>
            </div>
            <DataGridPro
                rows={roster}
                rowCount={totalCount}
                getRowHeight={() => 'auto'}
                columns={onSiteRosterColumns}
                pagination
                pageSize={selectedPageSize}
                page={page - START_PAGE}
                rowsPerPageOptions={SIZE_PER_PAGE_LIST}
                sortingOrder={DEFAULT_TABLE_SORTING_ORDER as GridSortDirection[]}
                filterMode='server'
                sortingMode='server'
                paginationMode='server'
                onFilterModelChange={tableFilterChangeCallback}
                onSortModelChange={tableSortChangeCallback}
                onPageChange={tablePageHandlerCallback}
                onPageSizeChange={tablePageSizeChange}
                sortModel={tableSort}
                loading={isSearchingIncidentRoster}
                autoHeight
                components={{
                    Toolbar: () => <CustomToolbar />,
                    Pagination: GridPagination
                }}
                componentsProps={DATA_GRID_COMMON_COMPONENTS_PROPS_STYLES}
                disableColumnMenu={true}
                disableSelectionOnClick
                sx={DATA_GRID_COMMON_SX_STYLES}
            />
        </div>
    );
};

export default IncidentDetailsOnSiteRosterDataGrid;
