import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { AppThunk } from '../../context/store';
import { toast } from 'react-toastify';
import {
    GET_FACMAN_KEYS_API_ENDPOINT,
    ADD_FACMAN_KEY_API_ENDPOINT,
    REMOVE_FACMAN_KEY_API_ENDPOINT
} from '../../lib/globals';
import { FacManKeysEntries } from '../../lib/types';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';

const { doRequest } = asyncRequest;

interface FacManKeysState {
    keys: FacManKeysEntries[];
    isGettingFacManKeys: boolean;
    isAddingFacManKey: boolean;
    isRemovingFacManKey: boolean;
    getFacManKeysError: string;
    addFacManKeyError: string;
    removeFacManKeyError: string;
}

export const initialState: FacManKeysState = {
    keys: [],
    isGettingFacManKeys: false,
    isAddingFacManKey: false,
    isRemovingFacManKey: false,
    getFacManKeysError: '',
    addFacManKeyError: '',
    removeFacManKeyError: ''
};

const facManKeysSlice = createSlice({
    name: 'facManKeys',
    initialState,
    reducers: {
        // eslint-disable-next-line no-empty-function
        startGetFacManKeys: (state) => {
            state.isGettingFacManKeys = true;
            state.getFacManKeysError = '';
        },
        // eslint-disable-next-line no-empty-function
        startAddFacManKey: (state) => {
            state.isAddingFacManKey = true;
            state.addFacManKeyError = '';
        },
        // eslint-disable-next-line no-empty-function
        startRemoveFacManKey: (state) => {
            state.isRemovingFacManKey = true;
            state.removeFacManKeyError = '';
        },
        // eslint-disable-next-line no-empty-function
        getFacManKeysSucceeded: (
            state,
            { payload }: PayloadAction<{ getFacManKeysResult: FacManKeysEntries[] }>
        ) => {
            state.keys = payload.getFacManKeysResult;
            state.isGettingFacManKeys = false;
        },
        // eslint-disable-next-line no-empty-function
        addFacManKeySucceeded: (state) => {
            state.isAddingFacManKey = false;
        },
        // eslint-disable-next-line no-empty-function
        removeFacManKeySucceeded: (state) => {
            state.isRemovingFacManKey = false;
        },
        // eslint-disable-next-line no-empty-function
        getFacManKeysFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingFacManKeys = false;
            state.getFacManKeysError = error;
        },
        // eslint-disable-next-line no-empty-function
        addFacManKeyFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isAddingFacManKey = false;
            state.addFacManKeyError = error;
        },
        // eslint-disable-next-line no-empty-function
        removeFacManKeyFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isRemovingFacManKey = false;
            state.removeFacManKeyError = error;
        },
        resetFacManKeysState: () => initialState
    }
});

export const {
    startGetFacManKeys,
    startAddFacManKey,
    startRemoveFacManKey,
    getFacManKeysSucceeded,
    addFacManKeySucceeded,
    removeFacManKeySucceeded,
    getFacManKeysFailed,
    addFacManKeyFailed,
    removeFacManKeyFailed,
    resetFacManKeysState
} = facManKeysSlice.actions;

export default facManKeysSlice.reducer;

export const getFacManKeysAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(resetFacManKeysState());
        dispatch(startGetFacManKeys());
        const response = await doRequest(
            GET_FACMAN_KEYS_API_ENDPOINT,
            {},
            GuardianAuth.createRequestAuthHeader()
        );
        const getFacManKeysResult = response?.data;
        dispatch(getFacManKeysSucceeded({ getFacManKeysResult }));
    } catch (error) {
        dispatch(getFacManKeysFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Failed to get Facility Manager Keys'} errorMessage={error.message} />
        );
    }
};

export const addFacManKeyAsync = (name: string, updatedBy: string): AppThunk => async (dispatch) => {
    const toastId = toast.info(`Adding ${name}...`);
    try {
        dispatch(startAddFacManKey());
        await doRequest(
            ADD_FACMAN_KEY_API_ENDPOINT,
            {
                name,
                updatedBy
            },
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(addFacManKeySucceeded());
        dispatch(getFacManKeysAsync());
        toast.update(toastId, {
            render: `Successfully added ${name}`,
            type: toast.TYPE.SUCCESS
        });
    } catch (error) {
        dispatch(addFacManKeyFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to add Facility Manager Key ${name}`}
                errorMessage={error.message}
            />
        );
    }
};

export const removeFacManKeyAsync = (name: string): AppThunk => async (dispatch) => {
    const toastId = toast.info(`Removing ${name}...`);
    try {
        dispatch(startRemoveFacManKey());
        await doRequest(
            REMOVE_FACMAN_KEY_API_ENDPOINT,
            {
                name
            },
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(removeFacManKeySucceeded());
        dispatch(getFacManKeysAsync());
        toast.update(toastId, {
            render: `Successfully removed ${name}`,
            type: toast.TYPE.SUCCESS
        });
    } catch (error) {
        dispatch(removeFacManKeyFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to remove Facility Manager Key ${name}`}
                errorMessage={error.message}
            />
        );
    }
};
