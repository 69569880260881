import React, { Dispatch, useState, useEffect } from 'react';
import 'animate.css';
import { Button, Form, Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { MdErrorOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import {
    addPhoneAnonymizationSettingAsync,
    deletePhoneAnonymizationSettingAsync,
    updatePhoneAnonymizationSettingAsync
} from '../../slices/admin/dataAnonymizationSlice';
import { OverrideTypes } from './Override';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import {
    CONFIRM_ADD_OVERRIDE_MESSAGE,
    CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE,
    CONFIRM_DELETE_OVERRIDE_MESSAGE,
    CONFIRM_EDIT_OVERRIDE_MESSAGE,
    DEFAULT_ANONYMIZE_TOGGLE,
    OVERRIDE_EXISTS_MESSAGE,
    PHONE_ANONYMIZATION_EVENT_TYPES,
    RP_BLUE
} from '../../lib/constants';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { showConfirmationDialog } from '../Commons';

type Props = {
    overrideType: OverrideTypes;
    isVisible: Dispatch<boolean>;
    selectedCountry?: string;
    selectedEventTypes?: string[];
    defaultSettings?: string;
};
type eventOptions = {
    value: string;
    label: string;
};
const PhoneNumberOverride = ({
    overrideType,
    isVisible,
    selectedCountry,
    selectedEventTypes,
    defaultSettings
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [country, setCountry] = useState<eventOptions>();
    const [isCountryValid, setIsCountryValid] = useToggle(false);
    const [eventTypes, setEventTypes] = useState<
        { incidentNewTypeName: string; incidentSubTypeName: string }[]
    >(
        selectedEventTypes
            ? (selectedEventTypes as string[]).map((eventType) => {
                  const types = eventType.split(' - ');
                  return { incidentNewTypeName: types[0], incidentSubTypeName: types[1] };
              })
            : []
    );
    const [selectOptions, setSelectedOptions] = useState<eventOptions[]>(
        selectedEventTypes
            ? (selectedEventTypes as string[]).map((eventType) => {
                  return { value: eventType, label: eventType };
              })
            : []
    );
    const { phoneAnonymizationSettings } = useSelector((state: RootState) => state.dataAnonymization);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);
    const [defaultSetting, setDefaultSetting] = useState<eventOptions | null>(
        defaultSettings ? { value: defaultSettings, label: defaultSettings } : null
    );
    countries.registerLocale(localeEn);

    useEffect(() => {
        setIsCountryValid(
            !phoneAnonymizationSettings.some((setting) => setting.countryCode === country?.value)
        );
    }, [country, phoneAnonymizationSettings]);

    const eventTypeOptions = Object.entries(PHONE_ANONYMIZATION_EVENT_TYPES).map((incidentType) => {
        return { value: incidentType[1], label: incidentType[1] };
    });

    const defaultOptions = [
        { value: DEFAULT_ANONYMIZE_TOGGLE.ON, label: DEFAULT_ANONYMIZE_TOGGLE.ON },
        { value: DEFAULT_ANONYMIZE_TOGGLE.OFF, label: DEFAULT_ANONYMIZE_TOGGLE.OFF }
    ];

    const countryOptions = Object.entries(countries.getNames('en')).map(([countryCode, countryName]) => ({
        value: countryCode,
        label: countryName
    }));

    const customSelectStyles = (isCountrySelect: boolean): any => ({
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: isCountrySelect && !isCountryValid ? 'red' : '#667085',
            '&:hover': {
                borderColor: isCountrySelect && !isCountryValid ? 'red' : '#667085'
            },
            boxShadow:
                isCountrySelect && !isCountryValid
                    ? '0 0 0 1px red'
                    : state.isFocused
                    ? `0 0 0 1px ${RP_BLUE}`
                    : 'none',
            fontSize: '14px'
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            fontSize: '14px'
        })
    });

    const ErrorIndicator = (): JSX.Element | null => {
        return !isCountryValid ? <MdErrorOutline size={16} style={{ color: '#E52817' }} /> : null;
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Option: string = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        className='mb-3'
                        type='checkbox'
                        checked={props.isSelected}
                        onChange={() => null}
                    />
                    <label style={{ fontSize: '10pt', paddingLeft: 10 }}>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const handleSubmit = async (): Promise<void> => {
        if (overrideType === OverrideTypes.AddOverride) {
            if (!country || !country.value) {
                return;
            }
            const confirmed = await showConfirmationDialog({
                title: CONFIRM_ADD_OVERRIDE_MESSAGE
            });
            if (confirmed) {
                dispatch(
                    addPhoneAnonymizationSettingAsync({
                        countryCode: country.value,
                        incidentTypeNames: eventTypes,
                        dateLastModified: DateTime.utc(),
                        modifiedBy: userCardholderInformation.login
                    })
                );
                isVisible(false);
            }
        } else if (overrideType === OverrideTypes.EditOverride) {
            const confirmed = await showConfirmationDialog({
                title: CONFIRM_EDIT_OVERRIDE_MESSAGE
            });
            if (confirmed && selectedCountry != null) {
                const alpha2Code = countries.getAlpha2Code(selectedCountry, 'en');
                if (alpha2Code) {
                    dispatch(
                        updatePhoneAnonymizationSettingAsync({
                            countryCode: alpha2Code,
                            incidentTypeNames: eventTypes,
                            dateLastModified: DateTime.utc(),
                            modifiedBy: userCardholderInformation.login
                        })
                    );
                } else {
                    toast.error(
                        <ToastErrorMessage
                            header={'Invalid country!'}
                            errorMessage={'Could not retrieve valid alpha-2 country code'}
                        />
                    );
                }
                isVisible(false);
            }
        } else {
            const confirmed = await showConfirmationDialog({
                title: CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE
            });
            if (confirmed && defaultSetting?.value == DEFAULT_ANONYMIZE_TOGGLE.OFF) {
                dispatch(
                    addPhoneAnonymizationSettingAsync({
                        countryCode: 'DEFAULT',
                        incidentTypeNames: Object.entries(PHONE_ANONYMIZATION_EVENT_TYPES).map(
                            (eventType) => {
                                const types = eventType[1].split(' - ');
                                return { incidentNewTypeName: types[0], incidentSubTypeName: types[1] };
                            }
                        ),
                        dateLastModified: DateTime.utc(),
                        modifiedBy: userCardholderInformation.login
                    })
                );
                isVisible(false);
            }
            if (confirmed && defaultSetting?.value == DEFAULT_ANONYMIZE_TOGGLE.ON) {
                dispatch(deletePhoneAnonymizationSettingAsync('DEFAULT'));
                isVisible(false);
            }
        }
    };

    const handleDelete = async (): Promise<void> => {
        const confirmed = await showConfirmationDialog({
            title: CONFIRM_DELETE_OVERRIDE_MESSAGE,
            icon: 'warning',
            iconColor: '#F29718'
        });
        if (confirmed && selectedCountry != null) {
            const alpha2Code = countries.getAlpha2Code(selectedCountry, 'en');
            if (alpha2Code) {
                dispatch(deletePhoneAnonymizationSettingAsync(alpha2Code));
            } else {
                toast.error(
                    <ToastErrorMessage
                        header={'Invalid country!'}
                        errorMessage={'Could not retrieve valid alpha-2 country code'}
                    />
                );
            }
            isVisible(false);
        }
    };

    const handleEventChange = (values: eventOptions[]): void => {
        setSelectedOptions(values ? values : []);
        setEventTypes(
            values
                ? values.map((value: eventOptions) => {
                      const types = value.value.split(' - ');
                      return { incidentNewTypeName: types[0], incidentSubTypeName: types[1] };
                  })
                : []
        );
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header className='modalHeader' closeButton>
                <Modal.Title className='modalTitle'>{overrideType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    {overrideType === OverrideTypes.AddOverride && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label className='formLabel'>Country</Form.Label>
                            <Select
                                styles={customSelectStyles(true)}
                                options={countryOptions}
                                onChange={(option: eventOptions) => setCountry(option)}
                                components={{ IndicatorSeparator: ErrorIndicator }}
                                placeholder={
                                    <Form.Text className='Placeholder'>Select a country...</Form.Text>
                                }
                                value={country}
                            />
                            {!isCountryValid && (
                                <Form.Text style={{ fontSize: '14px', color: '#E52817' }}>
                                    {OVERRIDE_EXISTS_MESSAGE}
                                </Form.Text>
                            )}
                        </Form.Group>
                    )}
                    {overrideType === OverrideTypes.EditOverride && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label className='formLabel'>Country</Form.Label>
                            <Select
                                styles={customSelectStyles(false)}
                                value={{
                                    value: countries.getAlpha2Code(selectedCountry as string, 'en'),
                                    label: selectedCountry
                                }}
                                isDisabled={true}
                                components={{ IndicatorSeparator: ErrorIndicator }}
                            />
                        </Form.Group>
                    )}
                    {overrideType === OverrideTypes.AddOverride && (
                        <Form.Group controlId='inputeventTypes'>
                            <Form.Label className='formLabel'>Event Type(s)</Form.Label>
                            <Select
                                styles={customSelectStyles(false)}
                                options={eventTypeOptions}
                                onChange={handleEventChange}
                                placeholder={
                                    <Form.Text className='Placeholder'>Select a event type(s)...</Form.Text>
                                }
                                value={selectOptions}
                                components={{ Option, IndicatorSeparator: () => null }}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={selectOptions.length === eventTypeOptions.length}
                                isMulti
                            />
                            <Form.Control.Feedback type='invalid'>
                                {OVERRIDE_EXISTS_MESSAGE}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {overrideType === OverrideTypes.EditOverride && (
                        <Form.Group className='mb-3' controlId='inputEventTypes'>
                            <Form.Label className='formLabel'>Event Type(s)</Form.Label>
                            <Select
                                styles={customSelectStyles(false)}
                                options={eventTypeOptions}
                                onChange={handleEventChange}
                                placeholder={
                                    <Form.Text className='Placeholder'>Select a event type(s)...</Form.Text>
                                }
                                value={selectOptions}
                                components={{ Option, IndicatorSeparator: () => null }}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={selectOptions.length === eventTypeOptions.length}
                                isMulti
                            />
                        </Form.Group>
                    )}
                    {overrideType === OverrideTypes.EditDefault && (
                        <Form.Group className='mb-3' controlId='inputEventTypes'>
                            <Form.Label className='formLabel'>Anonymization Setting</Form.Label>
                            <Select
                                styles={customSelectStyles(false)}
                                options={defaultOptions}
                                onChange={(option: eventOptions) => setDefaultSetting(option)}
                                value={defaultSetting}
                                components={{ IndicatorSeparator: () => null }}
                            />
                        </Form.Group>
                    )}
                    <Button
                        className='my-2 saveButton'
                        disabled={
                            (!country && !selectedCountry) ||
                            (overrideType === OverrideTypes.EditOverride && !eventTypes.length) ||
                            (overrideType !== OverrideTypes.EditDefault &&
                                !eventTypes.length &&
                                !selectedEventTypes?.length) ||
                            (overrideType === OverrideTypes.AddOverride && !isCountryValid)
                        }
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                    {overrideType === OverrideTypes.EditOverride && (
                        <Button
                            className='mb-4 deleteCancelButton'
                            style={{ color: '#E52817' }}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        className='mb-2 deleteCancelButton'
                        style={{ color: RP_BLUE }}
                        onClick={() => isVisible(false)}
                    >
                        Cancel
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PhoneNumberOverride;
