import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import '../../../css/RolePermissionsDashboard.css';
import { useAppDispatch } from '../../../context/store';
import { getRolePermissionsAsync } from '../../../slices/admin/rolePermissionsSlice';
import { RootState } from '../../../context/rootReducer';
import { STATUS_COLORS } from '../../../lib/constants';
import { RoleText } from '../../../lib/types';
import { StatusIndicator } from '../../../components/Visuals/StatusIndicator';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';

type Props = { tabName: 'admin' | 'business' | 'eventOwner' | 'user' };

const RolePermissionsDashboard = ({ tabName }: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const { [tabName]: tabsAndFeatures, isGettingRolePermissions } = useSelector(
        (state: RootState) => state.rolePermissions
    );

    useEffect(() => {
        if (!tabsAndFeatures) {
            dispatch(getRolePermissionsAsync());
        }
    }, []);

    // eslint-disable-next-line
    const mapAvailablePermissions = (values: RoleText[] | null | undefined) => {
        return (
            <DimmedLoader active={isGettingRolePermissions}>
                {values && values.length ? (
                    values.map((value, index) => {
                        if (value.isAble) {
                            return (
                                <li key={index} className='dashLi'>
                                    <span>{value.text}</span>
                                    <div>
                                        <StatusIndicator color={STATUS_COLORS.GREEN} />
                                    </div>
                                </li>
                            );
                        } else {
                            return (
                                <li key={index} className='dashLi'>
                                    <span>{value.text}</span>
                                    <div>
                                        <StatusIndicator color={STATUS_COLORS.GREY} />
                                    </div>
                                </li>
                            );
                        }
                    })
                ) : (
                    <li>Loading ...</li>
                )}
            </DimmedLoader>
        );
    };

    return (
        <Container className='baseDashContainer' style={{ marginLeft: 'unset' }}>
            <div className='dashView'>
                <div className='dashPartition'>
                    <label className='dashLabel'>tabs</label>
                    <ul className='dashUl'>{mapAvailablePermissions(tabsAndFeatures?.tabs)}</ul>
                </div>
                <div className='dashPartition ml-5'>
                    <label className='dashLabel'>features</label>
                    <ul>{mapAvailablePermissions(tabsAndFeatures?.features)}</ul>
                </div>
            </div>
        </Container>
    );
};

export default RolePermissionsDashboard;
