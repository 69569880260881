import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { AvailableRoutes } from '../../AppRoutes';
import { MdLockPerson, MdPersonSearch } from 'react-icons/md';
import { NavLink, useLocation } from 'react-router-dom';
import '../../css/Admin.css';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { version } from '../../../package.json';

const styles = {
    mainCardStyle: {
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto' as const,
        overflowX: 'hidden' as const,
        borderRadius: '5px',
        border: '2px solid #E7E7ED'
    },
    rolesTextStyle: {
        fontSize: '1rem',
        fontWeight: '600'
    },
    navLinkStyle: {
        fontSize: '11pt',
        textAlign: 'left' as const,
        color: '#000000'
    },
    navLinkActiveStyle: {
        fontSize: '13pt',
        fontWeight: '500',
        color: '#0D4AFF'
    }
};

const PermissionsSidebar = (): JSX.Element => {
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);
    const { webRole, mobileRole } = useSelector(
        (state: RootState) => state.userPermissions?.userPermissionRoles
    );
    const { pathname } = useLocation();
    const userLookupAccess = webRole !== 'User';

    return (
        <Card style={styles.mainCardStyle}>
            <Card.Body>
                <Row className='justify-content-center align-items-center'>
                    <Col sm='auto'>
                        <Image
                            className='mb-2'
                            rounded
                            style={{ width: '100%', maxWidth: 150 }}
                            src={userCardholderInformation.imgURL}
                            fluid={true}
                        />
                    </Col>
                </Row>
                <Row className='text-center'>
                    <Col>
                        <h4>{`${userCardholderInformation.firstName} ${userCardholderInformation.lastName}`}</h4>
                    </Col>
                </Row>
                <Row className='mt-n2 text-center'>
                    <Col>
                        <span>{userCardholderInformation.title}</span>
                    </Col>
                </Row>
                <hr />
                <Row className='mt-n1 pl-3'>
                    <span>{`Location: ${userCardholderInformation.location}`}</span>
                </Row>
                <hr />
                <Row className='mt-n1 pl-3'>
                    <span>{`Version: ${version.split('-')[0]}`}</span>
                </Row>
                <hr />
                <Row className='mt-1 pl-3'>
                    <span style={styles.rolesTextStyle}>Rally Point Roles:</span>
                </Row>
                <Row className='my-2 pl-3'>
                    <span>Web: {webRole}</span>
                </Row>
                <Row className='my-2 pl-3'>
                    <span>Mobile: {mobileRole}</span>
                </Row>
                <hr />
                <Nav className='mt-n3 ml-n3 flex-column'>
                    <Nav.Item className='mt-2'>
                        <Nav.Link
                            as={NavLink}
                            to={AvailableRoutes.profile}
                            style={styles.navLinkStyle}
                            activeStyle={styles.navLinkActiveStyle}
                        >
                            <MdLockPerson size={20} className='sidebarIcon' />
                            My Permissions
                        </Nav.Link>
                    </Nav.Item>
                    {userLookupAccess && (
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={AvailableRoutes.userLookup}
                                isActive={() =>
                                    pathname.includes('/userLookup') || pathname.includes('/siteLookup')
                                }
                                style={styles.navLinkStyle}
                                activeStyle={styles.navLinkActiveStyle}
                            >
                                <MdPersonSearch size={20} className='sidebarIcon' />
                                User Lookup
                            </Nav.Link>
                        </Nav.Item>
                    )}
                </Nav>
            </Card.Body>
        </Card>
    );
};

export default PermissionsSidebar;
