export const AuditTrailTitlePropMap = {
    id: 'Id',
    time: 'Time',
    user: 'User',
    role: 'Role',
    action: 'Action',
    message: 'Message'
};

export const AuditTrailPropMap = {
    id: 'id',
    time: 'time',
    user: 'user',
    role: 'role',
    action: 'action',
    message: 'message'
};
