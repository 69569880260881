import React from 'react';
import '../../css/StatusIndicator.css';

type Props = {
    color: string;
};

export const StatusIndicator = ({ color }: Props): JSX.Element => {
    return <span className={color} id='dot'></span>;
};
