import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import React from 'react';
import { toast } from 'react-toastify';
import { AppThunk } from '../../context/store';
import { DOWNLOAD_MOBILE_APP_API_ENDPOINT } from '../../lib/globals';

const { doRequest } = asyncRequest;

interface DownloadAppState {
    isDownloadingApp: boolean;
    downloadAppError: string;
    downloadUrl: string;
}

export const initialState: DownloadAppState = {
    isDownloadingApp: false,
    downloadAppError: '',
    downloadUrl: ''
};

const downloadAppSlice = createSlice({
    name: 'downloadApp',
    initialState,
    reducers: {
        startDownloadApp: (state) => {
            state.downloadAppError = initialState.downloadAppError;
            state.isDownloadingApp = true;
        },
        downloadAppSucceeded: (state) => {
            state.isDownloadingApp = false;
        },
        downloadAppFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isDownloadingApp = false;
            state.downloadAppError = error;
        },
        setDownloadUrl: (state, { payload }: PayloadAction<{ downloadUrl: string }>) => {
            const { downloadUrl } = payload;
            state.downloadUrl = downloadUrl;
        }
    }
});

export const {
    startDownloadApp,
    downloadAppSucceeded,
    downloadAppFailed,
    setDownloadUrl
} = downloadAppSlice.actions;

export default downloadAppSlice.reducer;

export const downloadAppAsync = (platform: string, phoneNumber: string): AppThunk => async (dispatch) => {
    const toastId = toast.info('Starting to generate a download link');
    try {
        dispatch(startDownloadApp());
        const response = await doRequest(
            DOWNLOAD_MOBILE_APP_API_ENDPOINT,
            {
                platform,
                phoneNumber
            },
            GuardianAuth.createRequestAuthHeader()
        );
        const downloadUrl = JSON.parse(response?.data?.body)?.downloadLink;
        dispatch(setDownloadUrl({ downloadUrl: downloadUrl ? downloadUrl : '' }));
        dispatch(downloadAppSucceeded());
        toast.update(toastId, {
            render: phoneNumber ? 'Download link sent to mobile device' : 'QR Code Generated',
            type: toast.TYPE.SUCCESS
        });
    } catch (error) {
        dispatch(downloadAppFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Failed to generate download link'} errorMessage={error.message} />
        );
    }
};
