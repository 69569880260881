import { GridColDef, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';
import {
    AuditTrailPropMap,
    AuditTrailTitlePropMap
} from '../../lib/dictionaries/IncidentAuditTrailDictionary';
import { convertInvalidToNA, phonetoolLinkFormatter } from '../Tables/TableFormatters';

export const incidentAuditTrailColumns: GridColDef[] = [
    {
        field: AuditTrailPropMap.time,
        headerName: AuditTrailTitlePropMap.time,
        flex: 0.9,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: AuditTrailPropMap.user,
        headerName: AuditTrailTitlePropMap.user,
        flex: 0.5,
        resizable: true,
        sortable: true,
        renderCell: (params) => phonetoolLinkFormatter(params.value)
    },
    {
        field: AuditTrailPropMap.role,
        headerName: AuditTrailTitlePropMap.role,
        flex: 0.5,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: AuditTrailPropMap.action,
        headerName: AuditTrailTitlePropMap.action,
        flex: 0.5,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: AuditTrailPropMap.message,
        headerName: AuditTrailTitlePropMap.message,
        flex: 2.0,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    }
];

export const DEFAULT_PAGE_SIZE = 10;

export const defaultSortModel: GridSortModel = [
    {
        field: AuditTrailPropMap.time,
        sort: 'desc' as GridSortDirection
    }
];
