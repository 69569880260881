import KatalLogger, { Level } from '@amzn/katal-logger';

import { KATAL_LOGGER_ENDPOINT } from '../globals';

const katalLoggerConfig = {
    url: KATAL_LOGGER_ENDPOINT,
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: true
};

export const Logger = new KatalLogger(katalLoggerConfig);
Logger.addErrorListener();
