export const UserLookupTitlePropMap = {
    firstName: 'First Name',
    lastName: 'Last Name',
    login: 'Login',
    location: 'Location',
    webPermissionRole: 'Web Permission Role',
    mobilePermissionRole: 'Mobile Permission Role',
    appVersion: 'App Version'
};

export const UserLookupPropMap = {
    firstName: 'firstName',
    lastName: 'lastName',
    login: 'login',
    location: 'location',
    webPermissionRole: 'webRole',
    mobilePermissionRole: 'appRole',
    appVersion: 'appVersion'
};
