export const GsocCallDirectoryTitlePropMap = {
    country: 'Country',
    dialCode: 'Code',
    phoneNumbers: 'Phone Numbers'
};

export const GsocCallDirectoryPropMap = {
    country: 'country',
    dialCode: 'dialCode',
    phoneNumbers: 'phoneNumbers'
};

export const GsocCallDirectoryFilterPropMap: { [key: string]: string } = {
    country: 'country',
    code: 'code',
    phoneNumbers: 'phoneNumbers'
};

export const GsocCallDirectorySortPropMap: { [key: string]: string } = {
    ...GsocCallDirectoryFilterPropMap,
    country: 'country.countryName'
};
