import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { AppThunk } from '../../context/store';
import { toast } from 'react-toastify';
import {
    GET_ROLE_PERMISSIONS_API_ENDPOINT,
    GET_PERMISSIONS_TEAMS_API_ENDPOINT,
    ADD_PERMISSIONS_TEAM_API_ENDPOINT,
    REMOVE_PERMISSIONS_TEAM_API_ENDPOINT,
    VALIDATE_GROUP_ID_API_ENDPOINT
} from '../../lib/globals';
import { RolePermissionsInformation, RoleInfo, PermissionsTeamsEntries } from '../../lib/types';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';

const { doRequest } = asyncRequest;

interface RolePermissionsDashboardState {
    eventOwnerPermissionsTeams: PermissionsTeamsEntries[];
    businessPermissionsTeams: PermissionsTeamsEntries[];
    adminPermissionsTeams: PermissionsTeamsEntries[];
    user: RoleInfo | null;
    eventOwner: RoleInfo | null;
    business: RoleInfo | null;
    admin: RoleInfo | null;
    version: string | null;
    isGettingRolePermissions: boolean;
    isGettingPermissionsTeams: boolean;
    isAddingPermissionsTeam: boolean;
    isRemovingPermissionsTeam: boolean;
    getRolePermissionsError: string;
    getPermissionsTeamsError: string;
    addPermissionsTeamError: string;
    removePermissionsTeamError: string;
    isValidTeamIdLoading: boolean;
    validTeamId: boolean;
    validteamIdError: string;
    // eslint-disable-next-line
    [key: string]: any;
}

interface teams {
    groupId: string;
    groupType: string;
}

export const initialState: RolePermissionsDashboardState = {
    eventOwnerPermissionsTeams: [],
    businessPermissionsTeams: [],
    adminPermissionsTeams: [],
    user: null,
    eventOwner: null,
    business: null,
    admin: null,
    version: null,
    isGettingRolePermissions: false,
    isGettingPermissionsTeams: false,
    isAddingPermissionsTeam: false,
    isRemovingPermissionsTeam: false,
    getRolePermissionsError: '',
    getPermissionsTeamsError: '',
    addPermissionsTeamError: '',
    removePermissionsTeamError: '',
    isValidTeamIdLoading: false,
    validTeamId: true,
    validteamIdError: ''
};

const rolePermissionsSlice = createSlice({
    name: 'rolePermissionsDashboard',
    initialState,
    reducers: {
        // eslint-disable-next-line no-empty-function
        startGetRolePermissionsDashboard: (state) => {
            state.isGettingRolePermissions = true;
            state.getRolePermissionsError = '';
        },
        // eslint-disable-next-line no-empty-function
        startGetPermissionsTeams: (state) => {
            state.isGettingPermissionsTeams = true;
            state.getPermissionsTeamsError = '';
        },
        // eslint-disable-next-line no-empty-function
        startAddPermissionsTeam: (state) => {
            state.isAddingPermissionsTeam = true;
            state.addPermissionsTeamError = '';
        },
        // eslint-disable-next-line no-empty-function
        startRemovePermissionsTeam: (state) => {
            state.isRemovingPermissionsTeam = true;
            state.removePermissionsTeamError = '';
        },
        // eslint-disable-next-line no-empty-function
        startValidateTeamId: (state) => {
            state.validTeamId = false;
            state.isValidTeamIdLoading = true;
        },
        getRolePermissionsSucceeded: (
            state,
            { payload }: PayloadAction<{ rolePermissionResult: RolePermissionsInformation }>
        ) => {
            const { rolePermissionResult } = payload;

            state.user = rolePermissionResult.User;
            state.eventOwner = rolePermissionResult.EventOwner;
            state.business = rolePermissionResult.Business;
            state.admin = rolePermissionResult.Admin;
            state.version = rolePermissionResult.version;
            state.isGettingRolePermissions = false;
        },
        // eslint-disable-next-line no-empty-function
        getEventOwnerPermissionsTeamsSucceeded: (
            state,
            { payload }: PayloadAction<{ permissionsTeamsResult: PermissionsTeamsEntries[] }>
        ) => {
            state.eventOwnerPermissionsTeams = payload.permissionsTeamsResult;
            state.isGettingPermissionsTeams = false;
        },
        // eslint-disable-next-line no-empty-function
        getBusinessPermissionsTeamsSucceeded: (
            state,
            { payload }: PayloadAction<{ permissionsTeamsResult: PermissionsTeamsEntries[] }>
        ) => {
            state.businessPermissionsTeams = payload.permissionsTeamsResult;
            state.isGettingPermissionsTeams = false;
        },
        // eslint-disable-next-line no-empty-function
        getAdminPermissionsTeamsSucceeded: (
            state,
            { payload }: PayloadAction<{ permissionsTeamsResult: PermissionsTeamsEntries[] }>
        ) => {
            state.adminPermissionsTeams = payload.permissionsTeamsResult;
            state.isGettingPermissionsTeams = false;
        },
        // eslint-disable-next-line no-empty-function
        addPermissionsTeamSucceeded: (state) => {
            state.isAddingPermissionsTeam = false;
        },
        // eslint-disable-next-line no-empty-function
        removePermissionsTeamsSucceeded: (state) => {
            state.isRemovingPermissionsTeam = false;
        },
        // eslint-disable-next-line no-empty-function
        validateTeamIdSucceeded: (state, { payload }: PayloadAction<{ validTeam: boolean }>) => {
            const { validTeam } = payload;
            state.validTeamId = validTeam;
            state.isValidTeamIdLoading = false;
        },
        // eslint-disable-next-line no-empty-function
        getRolePermissionsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingRolePermissions = false;
            state.getRolePermissionsError = error;
        },
        // eslint-disable-next-line no-empty-function
        getPermissionsTeamsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingPermissionsTeams = false;
            state.getPermissionsTeamsError = error;
        },
        // eslint-disable-next-line no-empty-function
        addPermissionsTeamFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isAddingPermissionsTeam = false;
            state.addPermissionsTeamError = error;
        },
        // eslint-disable-next-line no-empty-function
        removePermissionsTeamFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isRemovingPermissionsTeam = false;
            state.removePermissionsTeamError = error;
        },
        // eslint-disable-next-line no-empty-function
        validateTeamIdFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.validTeamId = false;
            state.removePermissionsTeamError = error;
            state.isValidTeamIdLoading = false;
        },
        resetRolePermissionsDashboard: (state) => {
            state.user = null;
            state.eventOwner = null;
            state.business = null;
            state.admin = null;
            state.version = null;
            state.isGettingRolePermissions = false;
            state.getRolePermissionsError = '';
        },
        resetPermissionsTeams: (state) => {
            state.eventOwnerPermissionsTeams = [];
            state.businessPermissionsTeams = [];
            state.adminPermissionsTeams = [];
            state.isGettingPermissionsTeams = false;
            state.isAddingPermissionsTeam = false;
            state.isRemovingPermissionsTeam = false;
            state.getPermissionsTeamsError = '';
            state.addPermissionsTeamError = '';
            state.removePermissionsTeamError = '';
        }
    }
});

export const {
    startGetRolePermissionsDashboard,
    startGetPermissionsTeams,
    startAddPermissionsTeam,
    startRemovePermissionsTeam,
    getRolePermissionsSucceeded,
    getEventOwnerPermissionsTeamsSucceeded,
    getBusinessPermissionsTeamsSucceeded,
    getAdminPermissionsTeamsSucceeded,
    addPermissionsTeamSucceeded,
    removePermissionsTeamsSucceeded,
    getRolePermissionsFailed,
    getPermissionsTeamsFailed,
    addPermissionsTeamFailed,
    removePermissionsTeamFailed,
    startValidateTeamId,
    validateTeamIdSucceeded,
    validateTeamIdFailed,
    resetRolePermissionsDashboard,
    resetPermissionsTeams
} = rolePermissionsSlice.actions;

export default rolePermissionsSlice.reducer;

export const getRolePermissionsAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(resetRolePermissionsDashboard());
        dispatch(startGetRolePermissionsDashboard());
        const response = await doRequest(
            GET_ROLE_PERMISSIONS_API_ENDPOINT,
            {},
            GuardianAuth.createRequestAuthHeader()
        );
        const rolePermissionResult = JSON.parse(response?.data?.body);
        dispatch(getRolePermissionsSucceeded({ rolePermissionResult }));
    } catch (error) {
        dispatch(getRolePermissionsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage header={'Failed to get Roles & Permissions'} errorMessage={error.message} />
        );
    }
};

export const getPermissionsTeamsAsync = (role: string): AppThunk => async (dispatch) => {
    try {
        dispatch(resetPermissionsTeams());
        dispatch(startGetPermissionsTeams());
        let response, permissionsTeamsResult;
        switch (role) {
            case 'eventOwner':
                response = await doRequest(
                    GET_PERMISSIONS_TEAMS_API_ENDPOINT,
                    { roles: [role] },
                    GuardianAuth.createRequestAuthHeader()
                );
                permissionsTeamsResult = response?.data?.eventOwner;
                dispatch(getEventOwnerPermissionsTeamsSucceeded({ permissionsTeamsResult }));
                break;
            case 'business':
                response = await doRequest(
                    GET_PERMISSIONS_TEAMS_API_ENDPOINT,
                    { roles: [role] },
                    GuardianAuth.createRequestAuthHeader()
                );
                permissionsTeamsResult = response?.data?.business;
                dispatch(getBusinessPermissionsTeamsSucceeded({ permissionsTeamsResult }));
                break;
            case 'admin':
                response = await doRequest(
                    GET_PERMISSIONS_TEAMS_API_ENDPOINT,
                    { roles: [role] },
                    GuardianAuth.createRequestAuthHeader()
                );
                permissionsTeamsResult = response?.data?.admin;
                dispatch(getAdminPermissionsTeamsSucceeded({ permissionsTeamsResult }));
                break;
            default:
                break;
        }
    } catch (error) {
        dispatch(getPermissionsTeamsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to get ${role} Permissions Teams`}
                errorMessage={error.message}
            />
        );
    }
};

export const addPermissionsTeamAsync = (
    name: string,
    extId: string,
    role: string,
    updatedBy: string
): AppThunk => async (dispatch) => {
    const toastId = toast.info(`Adding ${extId} to ${role}...`);
    try {
        switch (role) {
            case 'eventOwner':
                dispatch(startAddPermissionsTeam());
                await doRequest(
                    ADD_PERMISSIONS_TEAM_API_ENDPOINT,
                    {
                        name,
                        extId,
                        role,
                        updatedBy
                    },
                    GuardianAuth.createRequestAuthHeader()
                );
                dispatch(addPermissionsTeamSucceeded());
                break;
            case 'business':
                dispatch(startAddPermissionsTeam());
                await doRequest(
                    ADD_PERMISSIONS_TEAM_API_ENDPOINT,
                    {
                        name,
                        extId,
                        role,
                        updatedBy
                    },
                    GuardianAuth.createRequestAuthHeader()
                );
                dispatch(addPermissionsTeamSucceeded());
                break;
            case 'admin':
                dispatch(startAddPermissionsTeam());
                await doRequest(
                    ADD_PERMISSIONS_TEAM_API_ENDPOINT,
                    {
                        name,
                        extId,
                        role,
                        updatedBy
                    },
                    GuardianAuth.createRequestAuthHeader()
                );
                dispatch(addPermissionsTeamSucceeded());
                break;
            default:
                break;
        }
        toast.update(toastId, {
            render: `Successfully added ${extId} to ${role}`,
            type: toast.TYPE.SUCCESS
        });
        dispatch(getPermissionsTeamsAsync(role));
    } catch (error) {
        dispatch(addPermissionsTeamFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to add team ${extId} to ${role}`}
                errorMessage={error.message}
            />
        );
    }
};

export const removePermissionsTeamAsync = (role: string, extId: string): AppThunk => async (dispatch) => {
    const toastId = toast.info(`Removing ${extId} from ${role}...`);
    try {
        dispatch(startRemovePermissionsTeam());
        await doRequest(
            REMOVE_PERMISSIONS_TEAM_API_ENDPOINT,
            {
                extId,
                role
            },
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(removePermissionsTeamsSucceeded());
        dispatch(getPermissionsTeamsAsync(role));
        toast.update(toastId, {
            render: `Successfully removed ${extId} from ${role}`,
            type: toast.TYPE.SUCCESS
        });
    } catch (error) {
        dispatch(removePermissionsTeamFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to remove team ${extId} from ${role}`}
                errorMessage={error.message}
            />
        );
    }
};

export const validateTeamIdAsync = (groupId: string, groupType: string): AppThunk => async (dispatch) => {
    try {
        dispatch(startValidateTeamId());
        const teams: teams[] = [];
        teams.push({
            groupId,
            groupType
        });

        const response = await doRequest(
            VALIDATE_GROUP_ID_API_ENDPOINT,
            { teams: teams },
            GuardianAuth.createRequestAuthHeader()
        );
        const validTeam = response?.data?.response?.validTeams;
        dispatch(validateTeamIdSucceeded({ validTeam: validTeam?.length ? true : false }));
    } catch (error) {
        dispatch(validateTeamIdFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={`Failed to Validate team id ${groupId} for type ${groupType}`}
                errorMessage={error.message}
            />
        );
    }
};
