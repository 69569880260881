export const OnSiteRosterTitlePropMap = {
    id: 'Id',
    empId: 'Employee ID',
    name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    login: 'Login',
    managerLogin: 'Manager Login',
    empSite: 'Base Site',
    accountedFor: 'Accounted For',
    accountedBy: 'Accounted By',
    lastSeen: 'Last Seen At',
    type: 'Type',
    lastBadgePoint: 'Last Badge Point',
    lastBadgeTime: 'Last Badge Time',
    badgeType: 'Badge Type',
    imtMembership: 'IMT'
};

export const OnSiteRosterPropMap = {
    id: 'id',
    empId: 'empId',
    name: 'name',
    firstName: 'firstName',
    lastName: 'lastName',
    login: 'login',
    managerLogin: 'managerLogin',
    empSite: 'empSite',
    accountedFor: 'accountedFor',
    accountedBy: 'accountedBy',
    lastSeen: 'lastSeen',
    type: 'type',
    lastBadgePoint: 'lastBadgePoint',
    lastBadgeTime: 'lastBadgeTime',
    badgeType: 'badgeType',
    imtMembership: 'imtMembership'
};
