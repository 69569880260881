import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaFilter } from 'react-icons/fa';
import { useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import {
    GsocCallDirectoryPropMap,
    GsocCallDirectoryTitlePropMap
} from '../../lib/dictionaries/GsocCallDirectoryDictionary';
import { NoDataIndication } from './NoDataIndication';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import GsocPhoneNumber, { PhoneNumberTypes } from '../Modals/GsocPhoneNumber';
import { DIRECT_GSOC_NUMBERS_COUNTRY } from '../../lib/constants';

countries.registerLocale(localeEn);

const styles = {
    addEditButton: {
        backgroundColor: '#EBECFF',
        width: '50px',
        height: '30px',
        border: 'none',
        color: '#0036C8',
        fontSize: '9pt',
        fontWeight: '500',
        borderRadius: '4px',
        float: 'right' as const,
        marginRight: '10px'
    }
};

type Props = {
    isAdmin: boolean;
};

const DirectGsocNumbers = ({ isAdmin }: Props): JSX.Element => {
    const { gsocPhoneNumbers, isGettingGsocPhoneNumbers } = useSelector((state: RootState) => state.contacts);
    const directGsocPhoneNumbers = gsocPhoneNumbers.filter(
        (data) => data.country === DIRECT_GSOC_NUMBERS_COUNTRY
    );
    const [openEditModal, setOpenEditModal] = useToggle(false);

    const countryColumn = {
        dataField: GsocCallDirectoryPropMap.country,
        text: GsocCallDirectoryTitlePropMap.country,
        style: { width: '30%' }
    };

    const dialCodeColumn = {
        dataField: GsocCallDirectoryPropMap.dialCode,
        text: GsocCallDirectoryTitlePropMap.dialCode,
        style: { width: '20%' }
    };

    const phoneNumbersColumn = {
        dataField: GsocCallDirectoryPropMap.phoneNumbers,
        text: GsocCallDirectoryTitlePropMap.phoneNumbers,
        style: { width: '50%' }
    };

    const renderedColumns = [countryColumn, dialCodeColumn, phoneNumbersColumn];

    const renderedDirectGsocNumbers = gsocPhoneNumbers
        .filter((data) => data.country === DIRECT_GSOC_NUMBERS_COUNTRY)
        .map((data) => {
            return {
                country: 'Direct GSOC Numbers',
                dialCode: data.dialCode,
                phoneNumbers: data.phoneNumbers.join(', ')
            };
        });

    return (
        <div id='Overrides'>
            <label className='dataAnonTableLabel'>GSOC Numbers</label>
            {isAdmin && (
                <>
                    <Button
                        title='Edit'
                        size='sm'
                        type='Button'
                        onClick={() => {
                            setOpenEditModal(true);
                        }}
                        style={styles.addEditButton}
                    >
                        Edit
                    </Button>
                    {openEditModal && (
                        <GsocPhoneNumber
                            selectedCountry={DIRECT_GSOC_NUMBERS_COUNTRY}
                            phoneNumberType={PhoneNumberTypes.ChangeGsocNumber}
                            isVisible={setOpenEditModal}
                            selectedPhoneNumbers={directGsocPhoneNumbers[0]?.phoneNumbers}
                            selectedDialCode={directGsocPhoneNumbers[0]?.dialCode}
                        />
                    )}
                </>
            )}
            <ToolkitProvider
                keyField={GsocCallDirectoryPropMap.country}
                data={renderedDirectGsocNumbers}
                columns={renderedColumns}
            >
                {//eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props: any) => {
                    return (
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            bordered={false}
                            hover
                            wrapperClasses='table-responsive hide-table-headers'
                            keyField={GsocCallDirectoryPropMap.country}
                            data={renderedDirectGsocNumbers}
                            columns={renderedColumns}
                            noDataIndication={NoDataIndication(isGettingGsocPhoneNumbers)}
                        />
                    );
                }}
            </ToolkitProvider>
        </div>
    );
};

const LocalCountryNumbers = ({ isAdmin }: Props): JSX.Element => {
    const { gsocPhoneNumbers, isGettingGsocPhoneNumbers } = useSelector((state: RootState) => state.contacts);
    const [openAddModal, setOpenAddModal] = useToggle(false);
    const [openEditModal, setOpenEditModal] = useToggle(false);
    const [tableFilters, setTableFilters] = useToggle(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
    const [selectedDialCode, setSelectedDialCode] = useState('');

    const countryColumn = {
        dataField: GsocCallDirectoryPropMap.country,
        text: GsocCallDirectoryTitlePropMap.country,
        headerStyle: { width: '30%' },
        sort: true,
        sortFunc(a: string, b: string, order: string) {
            return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
        },
        filter: tableFilters
            ? textFilter({ placeholder: GsocCallDirectoryTitlePropMap.country + '...' })
            : false,
        style: {
            cursor: isAdmin ? 'pointer' : 'default',
            textDecoration: isAdmin ? 'underline' : 'none'
        },
        events: {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick: (e: any, column: any, columnIndex: any, row: any) => {
                if (isAdmin) {
                    setSelectedCountry(row.country);
                    setSelectedPhoneNumbers(row.phoneNumbers.split(', '));
                    setSelectedDialCode(row.dialCode);
                    setOpenEditModal(true);
                }
            }
        }
    };

    const dialCodeColumn = {
        dataField: GsocCallDirectoryPropMap.dialCode,
        text: GsocCallDirectoryTitlePropMap.dialCode,
        headerStyle: { width: '20%' },
        sort: true,
        sortFunc(a: string, b: string, order: string) {
            return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
        },
        filter: tableFilters
            ? textFilter({ placeholder: GsocCallDirectoryTitlePropMap.dialCode + '...' })
            : false
    };

    const phoneNumbersColumn = {
        dataField: GsocCallDirectoryPropMap.phoneNumbers,
        text: GsocCallDirectoryTitlePropMap.phoneNumbers,
        headerStyle: { width: '50%' },
        sort: true,
        sortFunc: (a: string, b: string, order: string) => {
            return order === 'asc' ? b.localeCompare(a) : a.localeCompare(b);
        },
        filter: tableFilters
            ? textFilter({ placeholder: GsocCallDirectoryTitlePropMap.phoneNumbers + '...' })
            : false
    };

    const renderedColumns = [countryColumn, dialCodeColumn, phoneNumbersColumn];

    const defaultSorted = [
        {
            dataField: GsocCallDirectoryPropMap.country,
            order: 'desc'
        }
    ];

    const renderedLocalCountryNumbers = gsocPhoneNumbers
        .filter((data) => data.country !== DIRECT_GSOC_NUMBERS_COUNTRY)
        .map((data) => {
            return {
                country: data.country,
                dialCode: data.dialCode,
                phoneNumbers: data.phoneNumbers.join(', ')
            };
        });

    return (
        <div id='Overrides'>
            <label className='dataAnonTableLabel'>Local Country Numbers</label>
            <Button
                title='Filter and Sort'
                onClick={() => setTableFilters((prevTableFilters: boolean) => !prevTableFilters)}
                size='sm'
                variant='outline'
                style={{ float: 'right' }}
            >
                <FaFilter style={{ marginBottom: 5 }} />
            </Button>
            {isAdmin && (
                <>
                    <Button
                        title='Add'
                        size='sm'
                        type='Button'
                        onClick={() => {
                            setOpenAddModal(true);
                        }}
                        style={styles.addEditButton}
                    >
                        Add
                    </Button>
                    {openAddModal && (
                        <GsocPhoneNumber
                            phoneNumberType={PhoneNumberTypes.AddLocalNumber}
                            isVisible={setOpenAddModal}
                        />
                    )}
                </>
            )}
            <ToolkitProvider
                keyField={GsocCallDirectoryPropMap.country}
                data={renderedLocalCountryNumbers}
                columns={renderedColumns}
            >
                {//eslint-disable-next-line @typescript-eslint/no-explicit-any
                (props: any) => {
                    return (
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            filter={filterFactory()}
                            bordered={false}
                            hover
                            wrapperClasses='table-responsive'
                            keyField={GsocCallDirectoryPropMap.country}
                            data={renderedLocalCountryNumbers}
                            columns={renderedColumns}
                            noDataIndication={NoDataIndication(isGettingGsocPhoneNumbers)}
                            defaultSorted={defaultSorted}
                        />
                    );
                }}
            </ToolkitProvider>
            {isAdmin && openEditModal && (
                <GsocPhoneNumber
                    selectedCountry={selectedCountry}
                    selectedPhoneNumbers={selectedPhoneNumbers}
                    phoneNumberType={PhoneNumberTypes.ChangeLocalNumber}
                    isVisible={setOpenEditModal}
                    selectedDialCode={selectedDialCode}
                />
            )}
        </div>
    );
};

export { DirectGsocNumbers, LocalCountryNumbers };
