import { GridColDef, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';
import React from 'react';
import { UserLookupPropMap, UserLookupTitlePropMap } from '../../lib/dictionaries/UserLookupDictionary';
import { convertInvalidToNA, phonetoolLinkFormatter } from '../Tables/TableFormatters';
import { AppVersion } from '../Visuals/AppVersion';

export const userLookUpColumns: GridColDef[] = [
    {
        field: UserLookupPropMap.firstName,
        headerName: UserLookupTitlePropMap.firstName,
        flex: 0.4,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: UserLookupPropMap.lastName,
        headerName: UserLookupTitlePropMap.lastName,
        flex: 0.4,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: UserLookupPropMap.login,
        headerName: UserLookupTitlePropMap.login,
        flex: 0.4,
        resizable: true,
        sortable: true,
        renderCell: (params) => phonetoolLinkFormatter(params.value)
    },
    {
        field: UserLookupPropMap.webPermissionRole,
        headerName: UserLookupTitlePropMap.webPermissionRole,
        flex: 0.5,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: UserLookupPropMap.mobilePermissionRole,
        headerName: UserLookupTitlePropMap.mobilePermissionRole,
        flex: 0.5,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: UserLookupPropMap.appVersion,
        headerName: UserLookupTitlePropMap.appVersion,
        flex: 0.3,
        resizable: true,
        sortable: true,
        renderCell: (params) => <AppVersion {...params} />
    },
    {
        field: UserLookupPropMap.location,
        headerName: UserLookupTitlePropMap.location,
        flex: 0.3,
        resizable: true,
        sortable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    }
];

export const DEFAULT_PAGE_SIZE = 10;

export const defaultSortModel: GridSortModel = [
    {
        field: UserLookupPropMap.firstName,
        sort: 'desc' as GridSortDirection
    }
];
