import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import React from 'react';
import { toast } from 'react-toastify';
import { AppThunk } from '../../context/store';
import {
    ADD_ANONYMIZATION_SETTING_API_ENDPOINT,
    DELETE_ANONYMIZATION_SETTING_API_ENDPOINT,
    GET_ANONYMIZATION_SETTINGS_API_ENDPOINT,
    UPDATE_ANONYMIZATION_SETTING_API_ENDPOINT,
    ADD_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT,
    DELETE_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT,
    GET_PHONE_ANONYMIZATION_SETTINGS_API_ENDPOINT,
    UPDATE_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT
} from '../../lib/globals';
import { AnonymizationSettings, PhoneAnonymizationSettings } from '../../lib/types';
import { DateTime } from 'luxon';

const { doRequest } = asyncRequest;

interface DataAnonymizationState {
    anonymizationSettings: AnonymizationSettings[];
    phoneAnonymizationSettings: PhoneAnonymizationSettings[];
    getAnonymizationSettingsError: string;
    addAnonymizationSettingError: string;
    updateAnonymizationSettingError: string;
    deleteAnonymizationSettingError: string;
    isLoadingAnonymizationSettings: boolean;
    isLoadingAddAnonymizationSetting: boolean;
    isLoadingUpdateAnonymizationSetting: boolean;
    isLoadingDeleteAnonymizationSetting: boolean;
    lastUpdate: DateTime;
}

export const initialState: DataAnonymizationState = {
    anonymizationSettings: [],
    phoneAnonymizationSettings: [],
    getAnonymizationSettingsError: '',
    addAnonymizationSettingError: '',
    updateAnonymizationSettingError: '',
    deleteAnonymizationSettingError: '',
    isLoadingAnonymizationSettings: false,
    isLoadingAddAnonymizationSetting: false,
    isLoadingUpdateAnonymizationSetting: false,
    isLoadingDeleteAnonymizationSetting: false,
    lastUpdate: DateTime.utc()
};

const dataAnonymizationSlice = createSlice({
    name: 'dataAnonymization',
    initialState,
    reducers: {
        startGetAnonymizationSettings: (state) => {
            const { anonymizationSettings, getAnonymizationSettingsError } = initialState;
            state.anonymizationSettings = anonymizationSettings;
            state.getAnonymizationSettingsError = getAnonymizationSettingsError;
            state.isLoadingAnonymizationSettings = true;
        },
        startGetPhoneAnonymizationSettings: (state) => {
            const { phoneAnonymizationSettings, getAnonymizationSettingsError } = initialState;
            state.phoneAnonymizationSettings = phoneAnonymizationSettings;
            state.getAnonymizationSettingsError = getAnonymizationSettingsError;
            state.isLoadingAnonymizationSettings = true;
        },
        getAnonymizationSettingsSucceeded: (
            state,
            { payload }: PayloadAction<{ anonymizationSettings: AnonymizationSettings[] }>
        ) => {
            const { anonymizationSettings } = payload;
            state.isLoadingAnonymizationSettings = false;
            state.anonymizationSettings = anonymizationSettings;
        },
        getPhoneAnonymizationSettingsSucceeded: (
            state,
            { payload }: PayloadAction<{ phoneAnonymizationSettings: PhoneAnonymizationSettings[] }>
        ) => {
            const { phoneAnonymizationSettings } = payload;
            state.isLoadingAnonymizationSettings = false;
            state.phoneAnonymizationSettings = phoneAnonymizationSettings;
        },
        getAnonymizationSettingsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isLoadingAnonymizationSettings = false;
            state.getAnonymizationSettingsError = error;
        },
        startUpdateAnonymizationSetting: (state) => {
            const { updateAnonymizationSettingError } = initialState;
            state.updateAnonymizationSettingError = updateAnonymizationSettingError;
            state.isLoadingUpdateAnonymizationSetting = true;
        },
        updateAnonymizationSettingSucceeded: (state) => {
            state.isLoadingUpdateAnonymizationSetting = false;
            state.lastUpdate = DateTime.utc();
        },
        updateAnonymizationSettingFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.updateAnonymizationSettingError = error;
            state.isLoadingUpdateAnonymizationSetting = false;
        },
        startDeleteAnonymizationSetting: (state) => {
            const { deleteAnonymizationSettingError } = initialState;
            state.deleteAnonymizationSettingError = deleteAnonymizationSettingError;
            state.isLoadingDeleteAnonymizationSetting = true;
        },
        deleteAnonymizationSettingSucceeded: (state) => {
            state.isLoadingDeleteAnonymizationSetting = false;
            state.lastUpdate = DateTime.utc();
        },
        deleteAnonymizationSettingFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.deleteAnonymizationSettingError = error;
            state.isLoadingDeleteAnonymizationSetting = false;
        },
        startAddAnonymizationSetting: (state) => {
            const { addAnonymizationSettingError } = initialState;
            state.addAnonymizationSettingError = addAnonymizationSettingError;
            state.isLoadingAddAnonymizationSetting = true;
        },
        addAnonymizationSettingSucceeded: (state) => {
            state.isLoadingAddAnonymizationSetting = false;
            state.lastUpdate = DateTime.utc();
        },
        addAnonymizationSettingFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.addAnonymizationSettingError = error;
            state.isLoadingAddAnonymizationSetting = false;
        }
    }
});

export const {
    startGetAnonymizationSettings,
    startGetPhoneAnonymizationSettings,
    getAnonymizationSettingsSucceeded,
    getPhoneAnonymizationSettingsSucceeded,
    getAnonymizationSettingsFailed,
    startAddAnonymizationSetting,
    addAnonymizationSettingFailed,
    addAnonymizationSettingSucceeded,
    startDeleteAnonymizationSetting,
    deleteAnonymizationSettingFailed,
    deleteAnonymizationSettingSucceeded,
    startUpdateAnonymizationSetting,
    updateAnonymizationSettingFailed,
    updateAnonymizationSettingSucceeded
} = dataAnonymizationSlice.actions;

export default dataAnonymizationSlice.reducer;

interface AnonymizationSettingRequest {
    countryCode: string;
    days: number | null;
    dateLastModified: DateTime;
    modifiedBy: string;
}

interface PhoneAnonymizationSettingRequest {
    countryCode: string;
    incidentTypeNames: { incidentNewTypeName: string; incidentSubTypeName: string }[];
    dateLastModified: DateTime;
    modifiedBy: string;
}

export const getAnonymizationSettingsAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetAnonymizationSettings());
        const response = await doRequest(
            GET_ANONYMIZATION_SETTINGS_API_ENDPOINT,
            {},
            GuardianAuth.createRequestAuthHeader()
        );
        const result = JSON.parse(response?.data?.body);
        const anonymizationSettings: AnonymizationSettings[] = [];
        result.map((setting: AnonymizationSettings) => {
            const { countryCode, days, dateLastModified, modifiedBy } = setting;
            anonymizationSettings.push({
                countryCode,
                days,
                dateLastModified,
                modifiedBy
            });
        });
        dispatch(getAnonymizationSettingsSucceeded({ anonymizationSettings }));
    } catch (error) {
        dispatch(getAnonymizationSettingsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={'Get data anonymization settings failed'}
                errorMessage={error.message}
            />
        );
    }
};

export const addAnonymizationSettingAsync = (request: AnonymizationSettingRequest): AppThunk => async (
    dispatch
) => {
    const toastId = toast.info('Adding anonymization setting...');
    try {
        dispatch(startAddAnonymizationSetting());
        await doRequest(
            ADD_ANONYMIZATION_SETTING_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(addAnonymizationSettingSucceeded());
        toast.update(toastId, {
            render: 'Successfully added anonymization setting',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getAnonymizationSettingsAsync());
    } catch (error) {
        dispatch(addAnonymizationSettingFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Add data anonymization setting failed'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const updateAnonymizationSettingAsync = (request: AnonymizationSettingRequest): AppThunk => async (
    dispatch
) => {
    const toastId = toast.info('Updating anonymization setting...');
    try {
        dispatch(startUpdateAnonymizationSetting());
        await doRequest(
            UPDATE_ANONYMIZATION_SETTING_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(updateAnonymizationSettingSucceeded());
        toast.update(toastId, {
            render: 'Successfully updated anonymization setting',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getAnonymizationSettingsAsync());
    } catch (error) {
        dispatch(updateAnonymizationSettingFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Update data anonymization setting failed'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const deleteAnonymizationSettingAsync = (countryCode: string): AppThunk => async (dispatch) => {
    const toastId = toast.info('Deleting anonymization setting...');
    try {
        dispatch(startDeleteAnonymizationSetting());
        await doRequest(
            DELETE_ANONYMIZATION_SETTING_API_ENDPOINT,
            { countryCode },
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(deleteAnonymizationSettingSucceeded());
        toast.update(toastId, {
            render: 'Successfully deleted anonymization setting',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getAnonymizationSettingsAsync());
    } catch (error) {
        dispatch(deleteAnonymizationSettingFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Delete data anonymization setting failed'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const getPhoneAnonymizationSettingsAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetPhoneAnonymizationSettings());
        const response = await doRequest(
            GET_PHONE_ANONYMIZATION_SETTINGS_API_ENDPOINT,
            {},
            GuardianAuth.createRequestAuthHeader()
        );
        const result = JSON.parse(response?.data?.body);
        const phoneAnonymizationSettings: PhoneAnonymizationSettings[] = [];
        result.map((setting: PhoneAnonymizationSettings) => {
            const {
                countryCode,
                incidentTypeNames,
                incidentNewTypeNames,
                dateLastModified,
                modifiedBy
            } = setting;
            phoneAnonymizationSettings.push({
                countryCode,
                incidentTypeNames,
                incidentNewTypeNames,
                dateLastModified,
                modifiedBy
            });
        });
        dispatch(getPhoneAnonymizationSettingsSucceeded({ phoneAnonymizationSettings }));
    } catch (error) {
        dispatch(getAnonymizationSettingsFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={'Get phone anonymization settings failed'}
                errorMessage={error.message}
            />
        );
    }
};

export const addPhoneAnonymizationSettingAsync = (
    request: PhoneAnonymizationSettingRequest
): AppThunk => async (dispatch) => {
    const toastId = toast.info('Adding anonymization setting...');
    try {
        dispatch(startAddAnonymizationSetting());
        await doRequest(
            ADD_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(addAnonymizationSettingSucceeded());
        toast.update(toastId, {
            render: 'Successfully added anonymization setting',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getPhoneAnonymizationSettingsAsync());
    } catch (error) {
        dispatch(addAnonymizationSettingFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Add phone anonymization setting failed'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const updatePhoneAnonymizationSettingAsync = (
    request: PhoneAnonymizationSettingRequest
): AppThunk => async (dispatch) => {
    const toastId = toast.info('Updating anonymization setting...');
    try {
        dispatch(startUpdateAnonymizationSetting());
        await doRequest(
            UPDATE_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(updateAnonymizationSettingSucceeded());
        toast.update(toastId, {
            render: 'Successfully updated anonymization setting',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getPhoneAnonymizationSettingsAsync());
    } catch (error) {
        dispatch(updateAnonymizationSettingFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Update phone anonymization setting failed'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const deletePhoneAnonymizationSettingAsync = (countryCode: string): AppThunk => async (dispatch) => {
    const toastId = toast.info('Deleting anonymization setting...');
    try {
        dispatch(startDeleteAnonymizationSetting());
        await doRequest(
            DELETE_PHONE_ANONYMIZATION_SETTING_API_ENDPOINT,
            { countryCode },
            GuardianAuth.createRequestAuthHeader()
        );
        dispatch(deleteAnonymizationSettingSucceeded());
        toast.update(toastId, {
            render: 'Successfully deleted anonymization setting',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getPhoneAnonymizationSettingsAsync());
    } catch (error) {
        dispatch(deleteAnonymizationSettingFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Delete phone anonymization setting failed'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};
