import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';

interface ToggleSwitchButtonInputType {
    reset: () => void;
    cancel: () => void;
    label?: string;
    style?: React.CSSProperties;
}

const ToggleAutoReloadSwitch: ({
    reset,
    cancel,
    label,
    style
}: ToggleSwitchButtonInputType) => JSX.Element = ({ reset, cancel, label, style }) => {
    const [reloadPage, setReloadPage] = useState(true);

    const onToggleChange = (): void => {
        reloadPage ? cancel() : reset();
        setReloadPage(!reloadPage);
    };

    return (
        <Form inline style={style}>
            <Form.Check type='switch' onChange={onToggleChange} checked={reloadPage} id={uuidv4()} />
            <Form.Label style={{ fontWeight: '400', fontSize: '14px' }}>Auto Reload {label}</Form.Label>
        </Form>
    );
};
export default ToggleAutoReloadSwitch;
