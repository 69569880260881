import Swal, { SweetAlertOptions } from 'sweetalert2';

export const showConfirmationDialog = async ({
    title,
    icon,
    iconColor,
    showCancelButton = true,
    confirmButtonText = 'Yes',
    confirmButtonColor = '#0D4AFF',
    cancelButtonColor = '#EBECFF',
    reverseButtons = true,
    customClass = {
        title: 'swal-custom-title',
        confirmButton: 'swal-confirm-button',
        cancelButton: 'swal-cancel-button',
        actions: 'swal-buttons-vertical'
    },
    showClass = {
        popup: `
            animate__animated 
            animate__fadeInDown 
            animate__faster
        `
    },
    hideClass = {
        popup: `
            animate__animated 
            animate__fadeOutUp 
            animate__faster
        `
    }
}: SweetAlertOptions): Promise<boolean> => {
    const { value: confirmed } = await Swal.fire({
        title,
        icon,
        iconColor,
        showCancelButton,
        confirmButtonText,
        confirmButtonColor,
        cancelButtonColor,
        reverseButtons,
        customClass,
        showClass,
        hideClass
    });

    return confirmed;
};
