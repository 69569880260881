import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { AppThunk } from '../../context/store';
import { toast } from 'react-toastify';
import {
    GET_GSOC_PHONE_NUMBERS_ENDPOINT,
    ADD_GSOC_PHONE_NUMBER_ENDPOINT,
    UPDATE_GSOC_PHONE_NUMBER_ENDPOINT,
    REMOVE_GSOC_PHONE_NUMBER_ENDPOINT
} from '../../lib/globals';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';

const { doRequest } = asyncRequest;

interface GsocPhoneNumber {
    country: string;
    dialCode: string;
    phoneNumbers: string[];
}

interface ContactsState {
    gsocPhoneNumbers: GsocPhoneNumber[];
    isGettingGsocPhoneNumbers: boolean;
    isAddingGsocPhoneNumber: boolean;
    isUpdatingGsocPhoneNumber: boolean;
    isRemovingGsocPhoneNumber: boolean;
    getGsocPhoneNumbersError: string;
    addGsocPhoneNumberError: string;
    updateGsocPhoneNumberError: string;
    removeGsocPhoneNumberError: string;
}

export const initialState: ContactsState = {
    gsocPhoneNumbers: [],
    isGettingGsocPhoneNumbers: false,
    isAddingGsocPhoneNumber: false,
    isUpdatingGsocPhoneNumber: false,
    isRemovingGsocPhoneNumber: false,
    getGsocPhoneNumbersError: '',
    addGsocPhoneNumberError: '',
    updateGsocPhoneNumberError: '',
    removeGsocPhoneNumberError: ''
};

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        startGetGsocPhoneNumbers: (state) => {
            state.gsocPhoneNumbers = initialState.gsocPhoneNumbers;
            state.isGettingGsocPhoneNumbers = true;
            state.getGsocPhoneNumbersError = initialState.getGsocPhoneNumbersError;
        },
        startAddGsocPhoneNumber: (state) => {
            state.isAddingGsocPhoneNumber = true;
            state.addGsocPhoneNumberError = initialState.addGsocPhoneNumberError;
        },
        startUpdateGsocPhoneNumber: (state) => {
            state.isUpdatingGsocPhoneNumber = true;
            state.updateGsocPhoneNumberError = initialState.addGsocPhoneNumberError;
        },
        startRemoveGsocPhoneNumber: (state) => {
            state.isRemovingGsocPhoneNumber = true;
            state.removeGsocPhoneNumberError = initialState.removeGsocPhoneNumberError;
        },
        getGsocPhoneNumbersSucceeded: (
            state,
            { payload }: PayloadAction<{ gsocPhoneNumbers: GsocPhoneNumber[] }>
        ) => {
            const { gsocPhoneNumbers } = payload;
            state.isGettingGsocPhoneNumbers = false;
            state.gsocPhoneNumbers = gsocPhoneNumbers;
        },
        addGsocPhoneNumberSucceeded: (state) => {
            state.isAddingGsocPhoneNumber = false;
        },
        updateGsocPhoneNumberSucceeded: (state) => {
            state.isUpdatingGsocPhoneNumber = false;
        },
        removeGsocPhoneNumberSucceeded: (state) => {
            state.isRemovingGsocPhoneNumber = false;
        },
        getGsocPhoneNumbersFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isGettingGsocPhoneNumbers = false;
            state.getGsocPhoneNumbersError = error;
        },
        addGsocPhoneNumberFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isAddingGsocPhoneNumber = false;
            state.addGsocPhoneNumberError = error;
        },
        updateGsocPhoneNumberFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isUpdatingGsocPhoneNumber = false;
            state.updateGsocPhoneNumberError = error;
        },
        removeGsocPhoneNumberFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isRemovingGsocPhoneNumber = false;
            state.removeGsocPhoneNumberError = error;
        }
    }
});

export const {
    startGetGsocPhoneNumbers,
    startAddGsocPhoneNumber,
    startUpdateGsocPhoneNumber,
    startRemoveGsocPhoneNumber,
    getGsocPhoneNumbersSucceeded,
    addGsocPhoneNumberSucceeded,
    updateGsocPhoneNumberSucceeded,
    removeGsocPhoneNumberSucceeded,
    getGsocPhoneNumbersFailed,
    addGsocPhoneNumberFailed,
    updateGsocPhoneNumberFailed,
    removeGsocPhoneNumberFailed
} = contactsSlice.actions;

export default contactsSlice.reducer;

export const getGsocPhoneNumbersAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(startGetGsocPhoneNumbers());
        const response = await doRequest(
            GET_GSOC_PHONE_NUMBERS_ENDPOINT,
            {},
            GuardianAuth.createRequestAuthHeader()
        );
        const gsocPhoneNumbersResult = response?.data?.response;
        dispatch(getGsocPhoneNumbersSucceeded({ gsocPhoneNumbers: gsocPhoneNumbersResult }));
    } catch (error) {
        dispatch(getGsocPhoneNumbersFailed({ error: error.message }));
        toast.error(
            <ToastErrorMessage
                header={'Failed to retrieve GSOC contact information'}
                errorMessage={error.message}
            />
        );
    }
};

export const addGsocPhoneNumberAsync = (request: GsocPhoneNumber): AppThunk => async (dispatch) => {
    const toastId = toast.info('Adding GSOC phone number info...');
    try {
        dispatch(startAddGsocPhoneNumber());
        await doRequest(ADD_GSOC_PHONE_NUMBER_ENDPOINT, request, GuardianAuth.createRequestAuthHeader());
        dispatch(addGsocPhoneNumberSucceeded());
        toast.update(toastId, {
            render: 'Successfully added GSOC phone number info',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getGsocPhoneNumbersAsync());
    } catch (error) {
        dispatch(addGsocPhoneNumberFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Failed to add GSOC phone number info'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const updateGsocPhoneNumberAsync = (request: GsocPhoneNumber): AppThunk => async (dispatch) => {
    const toastId = toast.info('Updating GSOC phone number info...');
    try {
        dispatch(startUpdateGsocPhoneNumber());
        await doRequest(UPDATE_GSOC_PHONE_NUMBER_ENDPOINT, request, GuardianAuth.createRequestAuthHeader());
        dispatch(updateGsocPhoneNumberSucceeded());
        toast.update(toastId, {
            render: 'Successfully updated GSOC phone number info',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getGsocPhoneNumbersAsync());
    } catch (error) {
        dispatch(updateGsocPhoneNumberFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Failed to update GSOC phone number info'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};

export const removeGsocPhoneNumberAsync = (request: { country: string }): AppThunk => async (dispatch) => {
    const toastId = toast.info('Removing GSOC phone number info...');
    try {
        dispatch(startRemoveGsocPhoneNumber());
        await doRequest(REMOVE_GSOC_PHONE_NUMBER_ENDPOINT, request, GuardianAuth.createRequestAuthHeader());
        dispatch(removeGsocPhoneNumberSucceeded());
        toast.update(toastId, {
            render: 'Successfully removed GSOC phone number info',
            type: toast.TYPE.SUCCESS
        });
        dispatch(getGsocPhoneNumbersAsync());
    } catch (error) {
        dispatch(removeGsocPhoneNumberFailed({ error: error.message }));
        toast.update(toastId, {
            render: (
                <ToastErrorMessage
                    header={'Failed to remove GSOC phone number info'}
                    errorMessage={error.message}
                />
            ),
            type: toast.TYPE.ERROR,
            autoClose: false
        });
    }
};
