import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import QRCode from 'qrcode.react';
import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, DropdownButton, Row, Dropdown } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import PhoneInput from 'react-phone-input-2';
import { connect, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { AvailableRoutes } from '../AppRoutes';
import { RootState } from '../context/rootReducer';
import { useAppDispatch } from '../context/store';
import { DOMAINS, RALLY_POINT_ROLES, RP_BLUE, RP_WIKI_URL } from '../lib/constants';
import { ADD_POLICY_API_ENDPOINT, REMOVE_POLICIES_API_ENDPOINT } from '../lib/globals';
import { PermissionsContext, UserContext, ComposedWithAuthAndPermissionsProps } from '../lib/types';
import { downloadAppAsync, setDownloadUrl } from '../slices/device/downloadAppSlice';
import { searchUserCardholderInformationAsync } from '../slices/userProfile/userProfileSlice';
import PERMISSIONS_CONTEXT from 'GuardianPermissions/lib/config/permissionsContext';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { toast } from 'react-toastify';
import {
    getUserPermissionActionsAsync,
    getUserPermissionRolesAsync
} from '../slices/permissions/userPermissionsSlice';
import { getStageEnv } from '../lib/helpers/helpers';
import rpLogo from '../lib/images/RPLogo.png';
const { doRequest } = asyncRequest;

type selectOptions = {
    value: string;
    label: string;
};

const DOWNLOAD_METHODS = {
    QR_CODE: 'QR Code',
    SMS: 'SMS'
};

const styles = {
    logoStyle: {
        width: '500px',
        height: 'auto'
    },
    stageEnvLabelStyle: {
        fontWeight: '500',
        fontSize: '20px',
        color: '#000000',
        position: 'relative' as const,
        top: '19px'
    },
    navLinkStyle: {
        color: '#667085',
        fontWeight: '400'
    },
    navLinkActiveStyle: {
        color: '#0D4AFF',
        borderBottom: '2px solid #0D4AFF'
    },
    buttonStyle: {
        backgroundColor: '#EAECF0',
        color: '#000000',
        borderColor: '#EAECF0',
        fontWeight: '500',
        borderRadius: '5px'
    },
    formGroupStyle: {
        margin: '0px 30px 20px 80px',
        width: '300px'
    },
    downloadButtonStyle: {
        backgroundColor: RP_BLUE,
        border: 'none',
        color: 'white',
        borderRadius: '4px',
        fontSize: '14px'
    }
};

const NavBar = ({ permissionsContext, userContext }: ComposedWithAuthAndPermissionsProps): JSX.Element => {
    const env = getStageEnv();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const [downloadApp, setDownloadApp] = useState(false);
    const [platform, setPlatform] = useState<selectOptions>();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [downloadMethod, setDownloadMethod] = useState<selectOptions>({
        value: DOWNLOAD_METHODS.QR_CODE,
        label: DOWNLOAD_METHODS.QR_CODE
    });

    const { userCardholderInformation, isGettingUserCardholderInformation } = useSelector(
        (state: RootState) => state.userProfile
    );
    const { isGettingUserPermissionRoles, isGettingUserPermissionActions } = useSelector(
        (state: RootState) => state.userPermissions
    );
    const { webRole } = useSelector((state: RootState) => state.userPermissions?.userPermissionRoles);
    const { showOSR, showIncidentHistory, showAdmin, testAccess } = useSelector(
        (state: RootState) => state.userPermissions?.userPermissionActions
    );

    const { downloadUrl, isDownloadingApp } = useSelector((state: RootState) => state.downloadApp);

    useEffect(() => {
        if (userContext && userContext.empId) {
            dispatch(searchUserCardholderInformationAsync(userContext.empId));
            dispatch(getUserPermissionRolesAsync({ permissionsContext, userContext }));
            dispatch(getUserPermissionActionsAsync({ permissionsContext, userContext }));
        }
    }, []);

    const hideGetMobileAppModal = (): void => setDownloadApp(false);
    const showGetMobileAppModal = (): void => setDownloadApp(true);
    const viewProfile = (): void => {
        history.push(AvailableRoutes.profile);
    };
    const viewContactGsoc = (): void => {
        history.push(AvailableRoutes.contact);
    };

    const onClickChangeRole = async (role: string): Promise<void> => {
        toast.info('Changing role permissions, page will refresh once finished...');
        const policyIdsToRemove = [];
        const { policyItems } = permissionsContext;
        if (policyItems) {
            for (const policyItem of policyItems[DOMAINS.DEFAULT]) {
                if (
                    policyItem.subject === userContext.empId &&
                    (policyItem.object === RALLY_POINT_ROLES.ADMIN ||
                        policyItem.object === RALLY_POINT_ROLES.EVENT_OWNER ||
                        policyItem.object === RALLY_POINT_ROLES.BUSINESS) &&
                    policyItem.object !== role
                ) {
                    policyIdsToRemove.push({ policyId: policyItem.policyId });
                }
            }
        }
        const policiesToRemove = {
            policyDocument: {
                profileName: 'RP2',
                policyItems: policyIdsToRemove
            }
        };
        const addPolicyRequest = {
            policyDocument: {
                profileName: 'RP2',
                ownerType: 'user',
                timestamp: Date.now(),
                policyItem: {
                    policyType: 'group',
                    subject: userContext.empId,
                    subjectType: 'user',
                    object: role
                }
            }
        };
        if (policyIdsToRemove && policyIdsToRemove.length > 0) {
            await doRequest(
                REMOVE_POLICIES_API_ENDPOINT,
                policiesToRemove,
                GuardianAuth.createRequestAuthHeader()
            );
        }
        await doRequest(ADD_POLICY_API_ENDPOINT, addPolicyRequest, GuardianAuth.createRequestAuthHeader());
        window.location.reload();
    };

    const platformOptions = [
        { value: 'Android', label: 'Android' },
        { value: 'IOS', label: 'iOS' }
    ];

    const downloadMethodOptions = [
        { value: DOWNLOAD_METHODS.QR_CODE, label: DOWNLOAD_METHODS.QR_CODE },
        { value: DOWNLOAD_METHODS.SMS, label: DOWNLOAD_METHODS.SMS }
    ];

    return (
        <Fragment>
            <Container fluid className='bg-white'>
                <Navbar expand='sm' className='py-0 mb-4'>
                    <Navbar.Brand
                        as={NavLink}
                        to='/'
                        style={{ color: '#707070', fontSize: '2rem' }}
                        className='py-0 mt-4'
                    >
                        <div>
                            <img src={rpLogo} alt={'Rally_Point_logo'} style={styles.logoStyle} />
                        </div>
                    </Navbar.Brand>
                    <span style={styles.stageEnvLabelStyle}>{env}</span>
                    <Navbar.Collapse className='justify-content-end py-0'>
                        <DimmedLoader
                            active={
                                isGettingUserCardholderInformation ||
                                isGettingUserPermissionRoles ||
                                isGettingUserPermissionActions
                            }
                        >
                            <Form inline>
                                {testAccess && (
                                    <DropdownButton
                                        id='changeRole'
                                        size='sm'
                                        className='mt-4 mr-3'
                                        variant='secondary'
                                        title='Change Role'
                                        menuAlign='right'
                                    >
                                        <Dropdown.Item onClick={() => onClickChangeRole('Admin')}>
                                            Admin
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => onClickChangeRole('Business')}>
                                            Business
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => onClickChangeRole('EventOwner')}>
                                            Event Owner
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => onClickChangeRole('User')}>
                                            User
                                        </Dropdown.Item>
                                    </DropdownButton>
                                )}
                                <Button
                                    size='sm'
                                    onClick={() => viewContactGsoc()}
                                    className='mt-4 mr-3'
                                    type='button'
                                    variant='secondary'
                                    style={styles.buttonStyle}
                                >
                                    Contact GSOC
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={showGetMobileAppModal}
                                    className='mt-4 mr-3'
                                    type='button'
                                    variant='secondary'
                                    style={styles.buttonStyle}
                                >
                                    Get Mobile App
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={() => window.open(RP_WIKI_URL, '_blank')}
                                    className='mt-4 mr-3'
                                    type='button'
                                    variant='secondary'
                                    style={styles.buttonStyle}
                                >
                                    Help
                                </Button>
                                <Button
                                    className='mb-n5 mt-2'
                                    onClick={() => viewProfile()}
                                    size='sm'
                                    variant='light'
                                    style={styles.buttonStyle}
                                >
                                    <Figure className='mt-1 mb-n2'>
                                        <Figure.Image
                                            rounded
                                            width={50}
                                            src={userCardholderInformation.imgURL}
                                        />
                                        <Figure.Caption> {userContext.login} </Figure.Caption>
                                        <Figure.Caption>{webRole}</Figure.Caption>
                                    </Figure>
                                </Button>
                            </Form>
                        </DimmedLoader>
                    </Navbar.Collapse>
                </Navbar>
                {/*
                // @ts-ignore */}
                <Nav variant='underline' className='pt-0'>
                    <Nav.Item>
                        <Nav.Link
                            as={NavLink}
                            to={AvailableRoutes.incidents}
                            exact
                            style={styles.navLinkStyle}
                            activeStyle={styles.navLinkActiveStyle}
                        >
                            EVENTS
                        </Nav.Link>
                    </Nav.Item>
                    {showOSR && (
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={AvailableRoutes.onSiteRoster}
                                style={styles.navLinkStyle}
                                activeStyle={styles.navLinkActiveStyle}
                            >
                                ON-SITE ROSTER
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {showOSR && (
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={AvailableRoutes.onSiteEmergencyResponders}
                                style={styles.navLinkStyle}
                                activeStyle={styles.navLinkActiveStyle}
                            >
                                ON-SITE EMERGENCY RESPONDERS
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {showIncidentHistory && (
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={AvailableRoutes.incidentHistory}
                                style={styles.navLinkStyle}
                                activeStyle={styles.navLinkActiveStyle}
                            >
                                EVENT HISTORY
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    <Nav.Item>
                        <Nav.Link
                            as={NavLink}
                            to={AvailableRoutes.profile}
                            isActive={() => pathname.includes('/profile')}
                            style={styles.navLinkStyle}
                            activeStyle={styles.navLinkActiveStyle}
                        >
                            PERMISSIONS
                        </Nav.Link>
                    </Nav.Item>
                    {showAdmin && (
                        <Nav.Item>
                            <Nav.Link
                                as={NavLink}
                                to={AvailableRoutes.admin}
                                style={styles.navLinkStyle}
                                activeStyle={styles.navLinkActiveStyle}
                            >
                                ADMIN
                            </Nav.Link>
                        </Nav.Item>
                    )}
                </Nav>
            </Container>
            <Modal
                show={downloadApp}
                onHide={() => {
                    hideGetMobileAppModal();
                    dispatch(setDownloadUrl({ downloadUrl: '' }));
                    setPlatform(undefined);
                }}
                centered
            >
                <Modal.Header className='modalHeader' closeButton>
                    <Modal.Title className='modalTitle'>Get Mobile App</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group style={styles.formGroupStyle}>
                        <Form.Label className='formLabel'>Platform</Form.Label>
                        <Select
                            components={{ IndicatorSeparator: () => null }}
                            options={platformOptions}
                            onChange={(option: selectOptions) => {
                                setPlatform(option);
                                dispatch(setDownloadUrl({ downloadUrl: '' }));
                            }}
                            placeholder='Select Platform'
                        />
                    </Form.Group>
                    <Form.Group style={styles.formGroupStyle}>
                        <Form.Label className='formLabel'>Download Method</Form.Label>
                        <Select
                            components={{ IndicatorSeparator: () => null }}
                            options={downloadMethodOptions}
                            onChange={(option: selectOptions) => {
                                setDownloadMethod(option);
                                dispatch(setDownloadUrl({ downloadUrl: '' }));
                            }}
                            value={downloadMethod}
                        />
                    </Form.Group>
                    {downloadMethod && downloadMethod.value === DOWNLOAD_METHODS.SMS ? (
                        <Fragment>
                            <Form.Group style={styles.formGroupStyle}>
                                <Form.Label className='formLabel'>SMS Number</Form.Label>
                                <PhoneInput
                                    onChange={(phone) => setPhoneNumber(phone)}
                                    country={'us'}
                                    enableSearch={true}
                                    disableSearchIcon={true}
                                />
                            </Form.Group>
                            <Form.Group style={styles.formGroupStyle}>
                                <Button
                                    style={{ ...styles.downloadButtonStyle, margin: '20px 0px 10px 50px' }}
                                    disabled={!phoneNumber || !platform || !platform.value}
                                    onClick={() => {
                                        if (platform && platform.value) {
                                            dispatch(downloadAppAsync(platform.value, phoneNumber));
                                        }
                                    }}
                                >
                                    Send SMS Download Link
                                </Button>
                            </Form.Group>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <DimmedLoader active={isDownloadingApp}>
                                {downloadUrl && (
                                    <Row>
                                        <Form.Group>
                                            <Alert variant='primary'>
                                                Please scan the QR Code with your mobile device in order to
                                                download the Rally Point App
                                            </Alert>
                                        </Form.Group>
                                        <Col md={{ span: 3, offset: 3 }}>
                                            <QRCode
                                                value={downloadUrl}
                                                size={200}
                                                bgColor={'#ffffff'}
                                                fgColor={'#000000'}
                                                level={'L'}
                                                includeMargin={false}
                                                renderAs={'svg'}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {!downloadUrl && (
                                    <Form.Group style={styles.formGroupStyle}>
                                        <Button
                                            style={{
                                                ...styles.downloadButtonStyle,
                                                margin: '20px 0px 10px 70px'
                                            }}
                                            disabled={!platform || !platform.value}
                                            onClick={() => {
                                                if (platform && platform.value) {
                                                    dispatch(downloadAppAsync(platform.value, ''));
                                                }
                                            }}
                                        >
                                            Generate QR Code
                                        </Button>
                                    </Form.Group>
                                )}
                            </DimmedLoader>
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

const mapPermissionStateToProps = (state: PermissionsContext): PermissionsContext => {
    const { permissionsContext } = state;
    return {
        permissionsContext
    };
};

const mapAuthStateToProps = (state: UserContext): UserContext => {
    const { userContext } = state;
    return {
        userContext
    };
};

export default compose(
    connect(mapPermissionStateToProps, null, null, { context: PERMISSIONS_CONTEXT }),
    connect(mapAuthStateToProps, null, null, { context: AUTH_CONTEXT })
)(NavBar) as React.ComponentType;
