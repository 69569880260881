export const PermissionsTeamsTitlePropMap = {
    name: 'Name',
    abacusId: 'Abacus ID',
    dateLastModified: 'Date Last Modified',
    user: 'User',
    remove: 'Remove'
};

export const PermissionsTeamsPropMap = {
    name: 'name',
    extId: 'extId',
    lastUpdated: 'lastUpdated',
    updatedBy: 'updatedBy',
    remove: 'remove'
};

export const FacManKeysTitlePropMap = {
    name: 'Name',
    dateLastModified: 'Date Last Modified',
    user: 'User',
    remove: 'Remove'
};

export const FacManKeysPropMap = {
    name: 'name',
    lastUpdated: 'lastUpdated',
    updatedBy: 'updatedBy',
    remove: 'remove'
};
