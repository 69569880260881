import React, { useEffect } from 'react';
import '../../css/Admin.css';
import { useAppDispatch } from '../../context/store';
import { getRolePermissionsAsync } from '../../slices/admin/rolePermissionsSlice';
import { AvailableRoutes } from '../../AppRoutes';
import { Redirect } from 'react-router-dom';

const RolesPermissions = (): JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getRolePermissionsAsync());
    }, []);
    return <Redirect to={AvailableRoutes.usersMenu} />;
};

export default RolesPermissions;
