import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { STAGE } from '../lib/globals';
import rootReducer, { RootState } from './rootReducer';
import { Action, configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { useDispatch } from 'react-redux';

export const history = createBrowserHistory({
    getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

export const store = configureStore({
    reducer: rootReducer,
    devTools: STAGE !== 'prod',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware(history))
});

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
